import React,{useEffect, useState} from 'react'
import {Container, Row, Col, Button, Dropdown, Modal, Form} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import CustomToggle from '../../../components/dropdowns'

import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'
import header from '../../../assets/images/page-img/profile-bg10.jpg' 
import ProfileHeader from '../../../components/profile-header';
import user9 from '../../../assets/images/user/1.jpg'
import { useDispatch, useSelector } from 'react-redux';

import small1 from '../../../assets/images/small/07.png'
import small2 from '../../../assets/images/small/08.png'
import small3 from '../../../assets/images/small/09.png'
import small4 from '../../../assets/images/small/10.png'
import small5 from '../../../assets/images/small/11.png'
import small6 from '../../../assets/images/small/12.png'
import small7 from '../../../assets/images/small/13.png'
import small8 from '../../../assets/images/small/14.png'
import { submitFunds } from '../../../store/create-events/post'
import { getFunds } from '../../../store/create-events/get'


const FundRaising = () => {
    const dispatch = useDispatch()
    const funds = useSelector((state) => state.fund.fund)
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [image, setImage] = useState(null);
    const [fundForm, setFundForm] = useState({
        fundRaisingName:'',
        fundRaiser:null,
        totalTargetAmount:Number,
        fundRaisingDescription:null

    })

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
          setImage(e.target.files[0]);
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFundForm((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

    const fundSubmitting = () =>{
        console.log(fundForm)
        console.log(image)
        dispatch(submitFunds(fundForm, image))
    }
    useEffect(() => {
      dispatch(getFunds())
      console.log(funds)
    }, [])
    
    return (
        <>
                <ProfileHeader  img={header} title="Fund Raising" />
                <div id="content-page" className="content-page">
                    <Container>
                            <div className='my-5 d-flex '>
                            <select class="form-select mb-3 w-25">
                            <option selected="">Location</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <select class="form-select mb-3 mx-4 w-25">
                            <option selected="">Any Date</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                            </div>
                        <Row className=''>
                        {isLoggedIn &&  
                         <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Fund Raising</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="user-img">
                                        <img src={user9} alt="userimg" className="avatar-60 rounded-circle"/>
                                    </div>
                                    <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                        <input type="text" className="form-control rounded" placeholder="Write something here..." style={{border: "none"}}/>
                                    </form>
                                </div>
                                <hr/>
                                <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small1} alt="icon" className="img-fluid me-2"/> Photo/Video</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small2} alt="icon" className="img-fluid me-2"/> Tag Friend</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3"><img src={small3} alt="icon" className="img-fluid me-2"/> Feeling/Activity</li>
                                    <li className="bg-soft-primary rounded p-2 pointer text-center">
                                        <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <i className="ri-more-fill h4"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="post-option">
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Check in</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Live Video</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Gif</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Watch Party</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Play with Friend</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </Card.Body>
                            
                            <Modal show={show} onHide={handleClose} size="lg" className='mt-5 pt-5'>
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Create Fund Raiser</h5>
                                            <button type="button" className="btn btn-secondary"  onClick={handleClose} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Fund Raising Name: *</Form.Label>
                                                        <Form.Control type="text" name="fundRaisingName" value={fundForm.fundRaisingName} onChange={handleChange}  />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Fund Raised By: </Form.Label>
                                                        <Form.Control type="text" name="fundRaiser" value={fundForm.fundRaiser} onChange={handleChange}   />                                                   </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Target Amount: </Form.Label>
                                                        <Form.Control type="number" name="totalTargetAmount" value={fundForm.totalTargetAmount} onChange={handleChange}   />                                                   </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Fund Raising Description</Form.Label>
                                                            <Form.Control as="textarea" name='fundRaisingDescription' value={fundForm.fundRaisingDescription} onChange={handleChange} rows="5"></Form.Control>
                                                        </Form.Group>
                                                </Col>
                                               
                                          
                                            </Row>
                                            <hr/>
                                            <div className="other-option">
                                            <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                                <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2">
                                                    <img src={small1} alt="icon" className="img-fluid me-2"/> Group_Image
                                                    <input type="file"  className="form-control rounded" onChange={handleFileChange} style={{border: "none", background:"#edf7ff", marginLeft:"2rem"}}/>
                                                    </li>
                                              </ul>
                                                
                                            </div>
                                            <Button variant="primary" onClick={fundSubmitting} className="d-block w-100 mt-3">Post</Button>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Fund Raising Modal */}
                                    <Modal show={show2} onHide={handleClose2} size="lg" className='mt-5 pt-5'>
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Donate Fund To Poor</h5>
                                            <button type="button" className="btn btn-secondary"  onClick={handleClose2} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                           headrerfor d
                                        </Modal.Body>
                                    </Modal>
                        </Card>} 
                           
                            {funds.map((product) => (
                                <Col key={product.fundId} lg="3">
                                        <Card className=" mb-4">
                                            <div className="top-bg-image">
                                                <img src={product.fundRaisingImage} className="img-fluid w-100" alt="group-bg"/>
                                            </div>
                                            <Card.Body className=" text-center">
                                            
                                                <div className="group-info pb-2">
                                                    <Link to="fund-detail">
                                                    <h4>{product.fundRaisingName}</h4>
                                                    </Link>
                                                    
                                                </div>
                                                <div className="group-details text-muted d-inline-block">
                                                    <p>{product.fundRaisingDescription}</p>
                                                </div>
                                                <div className="text-left">
                                                    <p className='small text-muted'>${product.totalAchievedAmount} of ${product.totalTargetAmount} generated</p>
                                                </div>
                                                <div class="progress mb-4">
                                                    <div className={`progress-bar w-${(product.totalAchievedAmount/product.totalTargetAmount)*100} ${(product.totalAcheivedAmount/product.totalTargetAmount)*100 ==="100" ? "bg-success":""}`} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">{product.totalInPercent}%</div>
                                                </div>
                                                <button  onClick={handleShow2} type="submit" className="btn btn-light d-block w-100">Donate Fund</button>
                                            </Card.Body>
                                        </Card>
                                </Col>
                            ))}
                            {/* {fundsData.map((product) => (
                                <Col key={product.id} lg="3">
                                        <Card className=" mb-4">
                                            <div className="top-bg-image">
                                                <img src={product.media} className="img-fluid w-100" alt="group-bg"/>
                                            </div>
                                            <Card.Body className=" text-center">
                                            
                                                <div className="group-info pb-2">
                                                    <Link to="fund-detail">
                                                    <h4>{product.title}</h4>
                                                    </Link>
                                                    
                                                </div>
                                                <div className="group-details text-muted d-inline-block">
                                                    <p>{product.desc}</p>
                                                </div>
                                                <div className="text-left">
                                                    <p className='small text-muted'>${product.totalGenerated} of ${product.totalAmount} generated</p>
                                                </div>
                                                <div class="progress mb-4">
                                                    <div className={`progress-bar w-${product.totalInPercent} ${product.totalInPercent ==="100" ? "bg-success":""}`} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">{product.totalInPercent}%</div>
                                                </div>
                                                <button  onClick={handleShow2} type="submit" className="btn btn-light d-block w-100">Donate Fund</button>
                                            </Card.Body>
                                        </Card>
                                </Col>
                            ))} */}
                        </Row>
                    </Container>
                </div>
        </>
    )
}

export default FundRaising