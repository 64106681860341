import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/reducers/reducers';
// import persistStore from 'redux-persist/es/persistStore';
// import persistReducer from 'redux-persist/es/persistReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key:'main-root',
  storage,
  whitelist: ['auth'],
}

const persistedReducer =  persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer:persistedReducer,
});

export const Persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>