import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    status: 'idle',
    error: null,
    isLoggedIn:false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setUserOut: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.status = 'idle';
        },
    },
});

export const { setUser, setStatus, setError, setUserOut } = authSlice.actions;
export default authSlice;
