// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';

// const firebaseConfig = {
//     apiKey: "AIzaSyBLFI8XEN1GhYoTRIQbYlasQXpJuMf20ZM",
//     authDomain: "ghluu-dev-85a9c.firebaseapp.com",
//     projectId: "ghluu-dev-85a9c",
//     storageBucket: "ghluu-dev-85a9c.appspot.com",
//     messagingSenderId: "936878530449",
//     appId: "1:936878530449:web:a1bdd1dbe9f9e8b7038430",
//     measurementId: "G-NRHGFVT2RE"
//   };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export const db = firebase.firestore();

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref} from "firebase/storage";

import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
 
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLFI8XEN1GhYoTRIQbYlasQXpJuMf20ZM",
  authDomain: "ghluu-dev-85a9c.firebaseapp.com",
  projectId: "ghluu-dev-85a9c",
  storageBucket: "ghluu-dev-85a9c.appspot.com",
  messagingSenderId: "936878530449",
  appId: "1:936878530449:web:a1bdd1dbe9f9e8b7038430",
  measurementId: "G-NRHGFVT2RE"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const storage = getStorage()
export const auth = getAuth();
export const analytics = getAnalytics(app);