import React,{useEffect, useState} from 'react'
import {Container, Card, Row, Col, Dropdown, OverlayTrigger, Tooltip, Button, Modal, Form} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'

// 
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';

//image
import img1 from '../../../assets/images/page-img/gi-1.jpg'
import user1 from '../../../assets/images/user/05.jpg'
import user2 from '../../../assets/images/user/06.jpg'
import user3 from '../../../assets/images/user/07.jpg'
import user4 from '../../../assets/images/user/08.jpg'
import user5 from '../../../assets/images/user/09.jpg'
import user6 from '../../../assets/images/user/10.jpg'
import user7 from '../../../assets/images/user/11.jpg'
import user8 from '../../../assets/images/user/12.jpg'
import user9 from '../../../assets/images/user/1.jpg'
import user10 from '../../../assets/images/user/04.jpg'
import user11 from '../../../assets/images/user/02.jpg'
import user12 from '../../../assets/images/user/03.jpg'
import user13 from '../../../assets/images/user/01.jpg'
import user14 from '../../../assets/images/user/01.jpg'
import small1 from '../../../assets/images/small/07.png'
import small2 from '../../../assets/images/small/08.png'
import small3 from '../../../assets/images/small/09.png'
import small4 from '../../../assets/images/small/10.png'
import small5 from '../../../assets/images/small/11.png'
import small6 from '../../../assets/images/small/12.png'
import small7 from '../../../assets/images/small/13.png'
import small8 from '../../../assets/images/small/14.png'
import img2 from '../../../assets/images/page-img/52.jpg'
import img5 from '../../../assets/images/user/1.jpg'
import icon1 from '../../../assets/images/icon/01.png'
import icon2 from '../../../assets/images/icon/02.png'
import icon3 from '../../../assets/images/icon/03.png'
import icon4 from '../../../assets/images/icon/04.png'
import icon5 from '../../../assets/images/icon/05.png'
import icon6 from '../../../assets/images/icon/06.png'
import icon7 from '../../../assets/images/icon/07.png'
import header from '../../../assets/images/page-img/profile-bg7.jpg' 
import { useDispatch, useSelector } from 'react-redux'
import { submitEvent } from '../../../store/create-events/post'
import { getEvents } from '../../../store/create-events/get'
import { useHistory } from 'react-router-dom';

const Events = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const dispatch = useDispatch()
    const history = useHistory()
    const events = useSelector((state) => state.event.event)

    const [image, setImage] = useState(null);
    const [eventForm, setEventForm] = useState({
        eventTitle:'',
        eventHost:'',
        eventDate:null,
        eventDuration:null,
        eventLocation:'',
        eventGuests:'',
        accessType:null,
        safetyPrecautions:'',
        eventDescription:'',

    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventForm((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      const handleFileChange = (e) => {
        if (e.target.files[0]) {
          setImage(e.target.files[0]);
        }
      };
    const eventSubmit =async() => {
        console.log("Dispatching")
        await dispatch(submitEvent(eventForm, image))
        handleClose()
        setEventForm({
            eventTitle:'',
            eventHost:'',
            eventDate:null,
            eventDuration:null,
            eventLocation:'',
            eventGuests:'',
            accessType:null,
            safetyPrecautions:'',
            eventDescription:'',
    
        } )
    }

    const handleHistory = (index) =>{
        history.push({
            pathname:"event-detail",
            state: {value: events[index]}
        })
    }

    useEffect(() => {
      dispatch(getEvents())
    //  console.log(events[0].eventImage)
      
    }, [dispatch])
    

    return (
        <>
        
        <ProfileHeader  img={header} title="Events" />
                <div id="content-page" className="content-page">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                            <div className="group-info d-flex align-items-center">
                                <div className="me-3">
                                    <img className="rounded-circle img-fluid avatar-100" src={img1} alt=""/>
                                </div>
                                <div className="info">
                                    <h4>Birthday Celebration</h4>
                                    <p className="mb-0"><i className="ri-lock-fill pe-2"></i>Private Group . 323 members</p>
                                </div>
                            </div>
                            <div className="group-member d-flex align-items-center  mt-md-0 mt-2">
                                <div className="iq-media-group me-3">
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user1} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user2} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user3} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user4} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user5} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user6} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user7} alt=""/>
                                    </Link>
                                    <Link to="#" className="iq-media">
                                        <img className="img-fluid avatar-40 rounded-circle" src={user8} alt=""/>
                                    </Link>
                                </div>
                                <button type="submit" className="btn btn-primary mb-2"><i className="ri-add-line"></i>Invite</button>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Events</h4>
                                </div>
                                <div className="card-post-toolbar">
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <i className="ri-more-fill h4"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                            <Dropdown.Item className="dropdown-item p-3" to="#">
                                                <div className="d-flex align-items-top">
                                                    <div className="icon h4"><i className="ri-notification-line"></i></div>
                                                    <div className="data ms-2">
                                                        <h6>Notifications</h6>
                                                        <p className="mb-0">Turn on notifications<br/> for this post</p>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item p-3" to="#">
                                                <div className="d-flex align-items-top">
                                                    <div className="icon h4"><i className="ri-save-line"></i></div>
                                                    <div className="data ms-2">
                                                        <h6>Pins</h6>
                                                        <p className="mb-0">Pin your favourite groups<br/> for quick access.</p>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item p-3" to="#">
                                                <div className="d-flex align-items-top">
                                                    <div className="icon h4"><i className="ri-pencil-line"></i></div>
                                                    <div className="data ms-2">
                                                        <h6>Following</h6>
                                                        <p className="mb-0">Follow or unfollow groups <br/>to control in News Feed.</p>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item p-3" to="#">
                                                <div className="d-flex align-items-top">
                                                    <div className="icon h4"><i className="ri-close-circle-line"></i></div>
                                                    <div className="data ms-2">
                                                        <h6>Membership</h6>
                                                        <p className="mb-0">Leave groups that no<br/> longer interest you.</p>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ul className="list-inline p-0 m-0">
                                    <li className="mb-md-3 pb-md-3 border-bottom members-list">
                                        <div className="iq-search-bar members-search p-0">
                                        <form action="#" className="searchbox w-auto">
                                            <input type="text" className="text search-input bg-soft-primary" placeholder="Type here to search..."/>
                                            <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                                        </form>
                                        </div>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                        <h6 className="mb-0">Birthday Celebration</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                        <h6 className="mb-0">New Year Celebration</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                        <h6 className="mb-0">Atlanta Retail Show</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                        <h6 className="mb-0">Holi Celebration</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="me-2"><i className="ri-arrow-right-fill h4"></i></div>
                                        <h6 className="mb-0">Republic Day</h6>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">About</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ul className="list-inline p-0 m-0">
                                    <li className="mb-3">
                                        <p className="mb-0">Event Planning's...</p>
                                    </li>                              
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-map-pin-line h4"></i></div>
                                        <h6 className="mb-0">Reserving a location for an event</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-truck-line h4"></i></div>
                                        <h6 className="mb-0">Coordinating Outside vendors</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-restaurant-2-line h4"></i></div>
                                        <h6 className="mb-0">Managing Staff</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-home-2-line h4"></i></div>
                                        <h6 className="mb-0">Selecting an overall event theme</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-hotel-line h4"></i></div>
                                        <h6 className="mb-0">Negotating hotel contracts</h6>
                                    </li>
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-briefcase-line h4"></i></div>
                                        <h6 className="mb-0">hiring a caterer</h6>
                                    </li>                              
                                    <li className="mb-3 d-flex align-items-center">
                                        <div className="avatar-40 rounded-circle bg-gray text-center me-3"><i className="ri-git-repository-line h4"></i></div>
                                        <h6 className="mb-0">Developing invitations</h6>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="9">
                        {isLoggedIn &&  
                         <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Event</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="user-img">
                                        <img src={user9} alt="userimg" className="avatar-60 rounded-circle"/>
                                    </div>
                                    <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                        <input type="text" className="form-control rounded" placeholder="Write something here..." style={{border: "none"}}/>
                                    </form>
                                </div>
                                <hr/>
                                <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small1} alt="icon" className="img-fluid me-2"/> Photo/Video</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small2} alt="icon" className="img-fluid me-2"/> Tag Friend</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3"><img src={small3} alt="icon" className="img-fluid me-2"/> Feeling/Activity</li>
                                    <li className="bg-soft-primary rounded p-2 pointer text-center">
                                        <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <i className="ri-more-fill h4"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="post-option">
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Check in</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Live Video</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Gif</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Watch Party</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Play with Friend</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </Card.Body>
                            
                            <Modal show={show} className='mt-5 pt-5' onHide={handleClose} size="lg">
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Create Event</h5>
                                            <button type="button" className="btn btn-danger"  onClick={handleClose} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                          
                                             <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Event Title: *</Form.Label>
                                                        <Form.Control type="text" name="eventTitle" value={eventForm.eventTitle} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Event Host: *</Form.Label>
                                                        <Form.Control type="text" name="eventHost" value={eventForm.eventHost} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Event Date: *</Form.Label>
                                                        <Form.Control type="date" name="eventDate" value={eventForm.eventDate} onChange={handleChange}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Event Duration: *</Form.Label>
                                                        <Form.Control type="text" name="eventDuration" value={eventForm.eventDuration} onChange={handleChange}/>
                                                    </Form.Group>
                                                </Col>
                                            
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Event Location: </Form.Label>
                                                        <Form.Control type="text" name="eventLocation" value={eventForm.eventLocation} onChange={handleChange}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Event Guests: </Form.Label>
                                                        <Form.Control type="text" name="eventGuests" value={eventForm.eventGuests} onChange={handleChange} placeholder='if more than one use " , " to indicate'/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="exampleFormControlSelect1">Access Type</Form.Label>
                                                        <select className="form-select"name="accessType" value={eventForm.accessType} onChange={handleChange}>
                                                            <option value="public">Public</option>
                                                            <option value="friends">Only Friends</option>
                                                            <option value="invitation">Only Invitation Link</option>
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Safety Precautions: *</Form.Label>
                                                        <Form.Control type="text" name="safetyPrecautions" value={eventForm.safetyPrecautions} onChange={handleChange}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Fund Raising Description</Form.Label>
                                                            <Form.Control as="textarea" name='eventDescription' value={eventForm.eventDescription} onChange={handleChange} rows="5"></Form.Control>
                                                        </Form.Group>
                                                </Col>
                                             
                                            </Row>
                                            <hr/>
                                            <div className="other-option">
                                            <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                                <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2">
                                                    <img src={small1} alt="icon" className="img-fluid me-2"/> Photo/Video
                                                    <input type="file" onChange={handleFileChange} className="form-control rounded" style={{border: "none", background:"#edf7ff", marginLeft:"4px"}}/>
                                                    </li>
                                              </ul>
                                                {/* <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="user-img me-3">
                                                            <img src={user9} alt="userimg" className="avatar-60 rounded-circle img-fluid"/>
                                                        </div>
                                                        <h6>Your Story</h6>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                                <span className="btn btn-primary">Friend</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu clemassName="dropdown-menu m-0 p-0">
                                                                <Dropdown.Item className="dropdown-item p-3" href="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-save-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Public</h6>
                                                                            <p className="mb-0">Anyone on or off Facebook</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item p-3" href="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-close-circle-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Friends</h6>
                                                                            <p className="mb-0">Your Friend on facebook</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item p-3" href="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-user-unfollow-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Friends except</h6>
                                                                            <p className="mb-0">Don't show to some friends</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item p-3" href="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-notification-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Only Me</h6>
                                                                            <p className="mb-0">Only me</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <Button variant="primary" onClick={eventSubmit} className="d-block w-100 mt-3">Post</Button>
                                        </Modal.Body>
                                    </Modal>
                        </Card>}
                       
                        <Row>
                        {events.map((product, index) => (
                                  <Col key={product.post_id} lg="6">
                                  <Card>
                                      <Card.Body>
                                          <div className="post-item">
                                              <div className="user-post-data py-3">
                                                  <div className="d-flex justify-content-between">
                                                    
                                                      <div className="w-100">
                                                          <div className="d-flex justify-content-between">
                                                              <div>
                                                                  <h5   className="mb-0 d-inline-block">{product.eventTitle}</h5>
                                                              </div>
                                                              <div className="card-post-toolbar">
                                                                  <Dropdown>
                                                                      <Dropdown.Toggle as={CustomToggle}>
                                                                          <i className="ri-more-fill h4"></i>
                                                                      </Dropdown.Toggle>
                                                                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                          <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-save-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Save Post</h6>
                                                                                      <p className="mb-0">Add this to your saved items</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-pencil-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Edit Post</h6>
                                                                                      <p className="mb-0">Update your post and saved items</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-close-circle-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Hide From Timeline</h6>
                                                                                      <p className="mb-0">See fewer posts like this.</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-delete-bin-7-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Delete</h6>
                                                                                      <p className="mb-0">Remove thids Post on Timeline</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className="dropdown-item p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-notification-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Notifications</h6>
                                                                                      <p className="mb-0">Turn on notifications for this post</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                      </Dropdown.Menu>
                                                                  </Dropdown>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="user-post">
                                                  
                                                        <img src={product.eventImage} alt="postimage" className="img-fluid w-100" />
                                              </div>
                                              <div className="comment-area mt-3">
                                                 <h6>{product.eventDate}</h6>
                                                 <p>{product.eventLocation}</p>                  
                                              </div>
                                              <div className='text-center'>

                                                <button onClick={()=> handleHistory(index)} className='btn btn-primary w-100 d-flex justify-content-center align-items-center'>
                                                    interest?
                                                </button>
                                              </div>
                                          </div>
                                      </Card.Body>
                              </Card>
                                  </Col>

                                    ))}
                 
                           
                            
                        </Row>
                        

                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default Events