import React, {useState, useEffect}  from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Modal, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { useDispatch, useSelector } from 'react-redux'

//image
import user1 from '../../../assets/images/user/1.jpg'
import user01 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import img1 from '../../../assets/images/small/07.png'
import img2 from '../../../assets/images/small/08.png'
import img3 from '../../../assets/images/small/09.png'
import img4 from '../../../assets/images/small/10.png'
import img5 from '../../../assets/images/small/11.png'
import img6 from '../../../assets/images/small/12.png'
import img7 from '../../../assets/images/small/13.png'
import img8 from '../../../assets/images/small/14.png'
import p1 from '../../../assets/images/page-img/p1.jpg'
import s1 from '../../../assets/images/page-img/s1.jpg'
import s2 from '../../../assets/images/page-img/s2.jpg'
import s3 from '../../../assets/images/page-img/s3.jpg'
import s4 from '../../../assets/images/page-img/s4.jpg'
import s5 from '../../../assets/images/page-img/s5.jpg'
import p2 from '../../../assets/images/page-img/p2.jpg'
import p3 from '../../../assets/images/page-img/p3.jpg'
import p4 from '../../../assets/images/page-img/p4.jpg'
import p5 from '../../../assets/images/page-img/p5.jpg'
import img42 from '../../../assets/images/page-img/42.png'
import icon1 from '../../../assets/images/icon/01.png'
import icon2 from '../../../assets/images/icon/02.png'
import icon3 from '../../../assets/images/icon/03.png'
import icon4 from '../../../assets/images/icon/04.png'
import icon5 from '../../../assets/images/icon/05.png'
import icon6 from '../../../assets/images/icon/06.png'
import icon7 from '../../../assets/images/icon/07.png'
import img9 from '../../../assets/images/small/img-1.jpg'
import img10 from '../../../assets/images/small/img-2.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import { submitImage, submitPost2 } from '../../../store/create-events/post'
import { getPosts } from '../../../store/create-events/get'
import { auth } from '../../../store/redux/firebase'
import getGeoLocation, { getLocation } from '../../../store/create-events/geolocation'




const storyData = [
    {
        id:1,
        name:"Web Design",
        timeOfPost:"1 hour ago",
        media:s3
    },
    {
        id:2,
        name:"App Design",
        timeOfPost:"3 hour ago",
        media: s1
    },
    {
        id:3,
        name:"Legal Services",
        timeOfPost:"9 hour ago",
        media:s4
    },
]
const eventData = [
    {
        id:1,
        name:"Independence Day",
        timeOfPost:"1 hour ago",
        media:s4
    },
    {
        id:2,
        name:"AU day",
        timeOfPost:"50 days more",
        media: s5
    },
    {
        id:3,
        name:"Raymond's Engagement",
        timeOfPost:"25 days more",
        media: s5
    },
  
]
const InSearchOf = () => {
    const dispatch = useDispatch()
    const posts = useSelector((state) => state.post.post)
    // const posts = useSelector((state) => state.post.post).filter((post) => post.owner.id === auth.currentUser.uid)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    // const {lat, lng} = getGeoLocation();
    const [image, setImage] = useState(null);
    const [text, setText] = useState(null);

    const formatDate = (date) => {
        const inputDate = new Date(date.replace(/"/g, ""));
        console.log(date + "date")
        console.log(inputDate)
        const now = new Date().getTime();
        const dateDiff = now - inputDate.getTime();
        const hoursDiff = Math.floor(dateDiff / (1000 * 60 * 60));
        if (hoursDiff < 24) {
          // Show hours if date is less than 24 hours from now
          return `${hoursDiff} hours ago`;
        } else {
          // Show days if date is more than 24 hours from now
          const daysDiff = Math.floor(hoursDiff / 24);
          return `${daysDiff} days ago`;
        }
        }
    useEffect(() => {
      dispatch(getPosts())

    }, [dispatch])
    
    return (
        <>
            <Container>
                <Row>

                    <Col lg={8} className="row m-0 p-0">
                    <Col sm={12} >
                        {/* {isLoggedIn &&
                        <Card id="post-modal-data" className="card-block card-stretch card-height">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Create Post</h4>
                                    </div>
                                </div>
                                <Card.Body >
                                    <div className="d-flex align-items-center">
                                        <div className="user-img">
                                            {auth.currentUser.photoURL && <img src={auth.currentUser.photoURL} alt="user1" className="avatar-60 rounded-circle"/>}
                                        </div>
                                        <form className="post-text ms-3 w-100 "   onClick={handleShow}>
                                            <input  className="form-control rounded" placeholder="Write something here..." style={{border:"none"}}/>
                                        </form>
                                    </div>
                                    <hr></hr>
                                    <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                        <li className="me-3 mb-md-0 mb-2">
                                            <Link to="#" className="btn btn-soft-primary">
                                                <img src={img1} alt="icon" className="img-fluid me-2"/> Photo/Video
                                            </Link>
                                        </li>
                                        <li className="me-3 mb-md-0 mb-2">
                                            <Link to="#" className="btn btn-soft-primary">
                                                <img src={img2} alt="icon" className="img-fluid me-2"/> Tag Friend
                                            </Link>
                                        </li>
                                        <li className="me-3">
                                            <Link to="#" className="btn btn-soft-primary">
                                                <img src={img3} alt="icon" className="img-fluid me-2"/> Feeling/Activity
                                            </Link>
                                        </li>
                                        <li>
                                            <button className=" btn btn-soft-primary">
                                                <div className="card-header-toolbar d-flex align-items-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as='div'className="lh-1">
                                                        <span className="material-symbols-outlined">
                                                            more_horiz
                                                        </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item  onClick={handleShow}  href="#">Check in</Dropdown.Item>
                                                            <Dropdown.Item  onClick={handleShow}  href="#">Live Video</Dropdown.Item>
                                                            <Dropdown.Item  onClick={handleShow}  href="#">Gif</Dropdown.Item> 
                                                            <Dropdown.Item  onClick={handleShow}  href="#">Watch Party</Dropdown.Item> 
                                                            <Dropdown.Item  onClick={handleShow}  href="#">Play with Friend</Dropdown.Item> 
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </button>
                                        </li>
                                    </ul>
                                </Card.Body>
                                <Modal size="lg" className="fade mt-5 pt-5" id="post-modal" onHide={handleClose} show={show} >
                                    <Modal.Header  className="d-flex justify-content-between">
                                        <Modal.Title id="post-modalLabel">Create Post</Modal.Title>
                                        <Link to="#" className="lh-1" onClick={handleClose} >
                                            <span className="material-symbols-outlined">close</span>
                                        </Link>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="user-img">
                                                <img src={auth.currentUser.photoURL} alt="user1" className="avatar-60 rounded-circle img-fluid"/>
                                            </div>
                                            <form className="post-text ms-3 w-100 "  data-bs-toggle="modal" data-bs-target="#post-modal">
                                            <input type="text" value={text} onChange={handleTextChange} className="form-control rounded" placeholder="Write something here..." style={{border:"none"}}/>
                                            </form>
                                        </div>
                                        <hr/>
                                     
                                        <div className='mx-2'>
                                        <Form>
                                            <Row className=' '>
                                                <Col>
                                                    <Form.Group className=" form-group">
                                                        <Form.Control onChange={handleFileChange} type="file" id="customFile1"/>
                                                        <Form.Label className="custom-file-input">Photo/Video</Form.Label>{' '}

                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" placeholder="Tag Your Friend"/>
                                                </Col>
                                            </Row>
                                        </Form>
                                        </div>
                                        <hr/>
                                  
                                        <button onClick={submitPost} type="submit" className="btn btn-primary d-block w-100 mt-3">Post</button>
                                    </Modal.Body>
                                </Modal>
                            </Card>
                        } */}
                            
                        </Col>
                   
                    {posts.map((product, index) => (
                                    <Col  key={product.postId} sm={12}>

                                          <div className="card card-block card-stretch card-height">
                                          <div className="card-body">
                                              <div className="user-post-data">
                                                  <div className="d-flex justify-content-between">
                                                      <div className="me-3">
                                                          <img className="rounded-circle img-fluid" width={60} src={product.owner.profileImage} alt=""/>
                                                      </div>
                                                      <div className="w-100">
                                                          <div className="d-flex  justify-content-between">
                                                              <div>
                                                                  <h5 className="mb-0 d-inline-block">{product.owner.name}</h5>
                                                                  {/* <span className="mb-0 ps-1 d-inline-block">Added New Image in a Post</span> */}
                                                                  <p className="mb-0 text-primary">{formatDate(product.createdAt)}</p>
                                                              </div>
                                                              <div className="card-post-toolbar">
                                                                  <Dropdown>
                                                                      <Dropdown.Toggle variant="bg-transparent">
                                                                      <span className="material-symbols-outlined">
                                                                          more_horiz
                                                                      </span>
                                                                      </Dropdown.Toggle>
                                                                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                          <Dropdown.Item className=" p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <div className="h4">
                                                                                      <i className="ri-save-line"></i>
                                                                                  </div>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Save Post</h6>
                                                                                      <p className="mb-0">Add this to your saved items</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className= "p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-close-circle-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Hide Post</h6>
                                                                                      <p className="mb-0">See fewer posts like this.</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className=" p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-user-unfollow-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Unfollow User</h6>
                                                                                      <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item className=" p-3" to="#">
                                                                              <div className="d-flex align-items-top">
                                                                                  <i className="ri-notification-line h4"></i>
                                                                                  <div className="data ms-2">
                                                                                      <h6>Notifications</h6>
                                                                                      <p className="mb-0">Turn on notifications for this post</p>
                                                                                  </div>
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                      </Dropdown.Menu>
                                                                  </Dropdown>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="mt-3">
                                                  <p>{product.body}</p>
                                              </div>
                                              <div className="user-post">
                                                  <Link to="#"><img src={product.images[0]} alt="post1" className="img-fluid rounded w-100"/></Link>
                                              </div>
                                              <div className="comment-area mt-3">
                                                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                      <div className="like-block position-relative d-flex align-items-center">
                                                          <div className="d-flex align-items-center">
                                                              <div className="like-data">
                                                                  <Dropdown>
                                                                      <Dropdown.Toggle  as={CustomToggle} >
                                                                          <img src={icon1} className="img-fluid" alt=""/>
                                                                      </Dropdown.Toggle>
                                                                      <Dropdown.Menu className=" py-2">
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid" alt=""/></OverlayTrigger>
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid" alt=""/></OverlayTrigger>
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid" alt=""/></OverlayTrigger>
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid" alt=""/></OverlayTrigger>
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid" alt=""/></OverlayTrigger>
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid" alt=""/></OverlayTrigger>
                                                                              <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid" alt=""/></OverlayTrigger>
                                                                      </Dropdown.Menu>
                                                                  </Dropdown>
                                                              </div>
                                                              <div className="total-like-block ms-2 me-3">
                                                                  <Dropdown>
                                                                      <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                                      140 Likes
                                                                      </Dropdown.Toggle>
                                                                      <Dropdown.Menu>
                                                                          <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                          <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                          <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                          <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                          <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                          <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                          <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                                      </Dropdown.Menu>
                                                                  </Dropdown>
                                                              </div>
                                                          </div>
                                                          <div className="total-comment-block">
                                                              <Dropdown>
                                                                  <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                                  20 Comment
                                                                  </Dropdown.Toggle>
                                                                  <Dropdown.Menu>
                                                                      <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                      <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                      <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                      <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                      <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                      <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                      <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                                  </Dropdown.Menu>
                                                              </Dropdown>
                                                          </div>
                                                      </div>
                                                      <ShareOffcanvas />
                                                  </div>
                                                  <hr/>
                                                  <ul className="post-comments list-inline p-0 m-0">
                                                      <li className="mb-2">
                                                          <div className="d-flex ">
                                                              <div className="user-img">
                                                                  <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                              </div>
                                                              <div className="comment-data-block ms-3">
                                                                  <h6>Raymond</h6>
                                                                  <p className="mb-0">Nice one there</p>
                                                                  <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                      <Link to="#">like</Link>
                                                                      <Link to="#">reply</Link>
                                                                      <Link to="#">translate</Link>
                                                                      <span> 5 min </span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </li>
                                                      <li>
                                                          <div className="d-flex ">
                                                              <div className="user-img">
                                                                  <img src={user01} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                              </div>
                                                              <div className="comment-data-block ms-3">
                                                                  <h6>Rita Mortey</h6>
                                                                  <p className="mb-0">Good Job 😍</p>
                                                                  <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                      <Link to="#">like</Link>
                                                                      <Link to="#">reply</Link>
                                                                      <Link to="#">translate</Link>
                                                                      <span> 5 min </span>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </li>
                                                  </ul>
                                                  <form className="comment-text d-flex align-items-center mt-3" >
                                                      <input type="text" className="form-control rounded" placeholder="Enter Your Comment"/>
                                                      <div className="comment-attagement d-flex">
                                                          <Link to="#"><i className="ri-link me-3"></i></Link>
                                                          <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                          <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                                      </div>
                                                  </form>
                                              </div>    
                                          </div>
                                      </div>
                                      </Col>

                                    ))}
                       
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Market Place</h4>
                                </div>
                            </div>
                            <Card.Body>
                                <ul className="media-story list-inline m-0 p-0">
                                    <li className="d-flex mb-3 align-items-center">
                                        <i className="ri-add-line"></i>
                                        <div className="stories-data ms-3">
                                            <h5>Add Your Service</h5>
                                            <p className="mb-0">time added</p>
                                        </div>
                                    </li>
                                    {storyData.map((product) => (
                                    <li key={product.id} className="d-flex mb-3 align-items-center active">
                                    <img src={product.media} alt="story-img" className="rounded-circle img-fluid"/>
                                    <div className="stories-data ms-3">
                                        <h5>{product.name}</h5>
                                        <p className="mb-0">{product.timeOfPost}</p>
                                    </div>
                                </li>

                                    ))}
                                  
                                </ul>
                                <Link to="#" className="btn btn-primary d-block mt-3">See All</Link>
                            </Card.Body>
                        </Card>
                        <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Events</h4>
                                </div>
                                <div className="card-header-toolbar d-flex align-items-center">
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                            <i className="ri-more-fill h4"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className=" dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Dropdown.Item  href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <Card.Body>
                                <ul className="media-story list-inline m-0 p-0">
                                {eventData.map((product) => (
                                   <li key={product.id} className="d-flex mb-4 align-items-center ">
                                   <img src={product.media} alt="story1" className="rounded-circle img-fluid"/>
                                   <div className="stories-data ms-3">
                                       <h5>{product.name}</h5>
                                       <p className="mb-0">{product.timeOfPost}</p>
                                   </div>
                               </li>

                                    ))}
                                </ul>
                            </Card.Body>
                        </Card>
                        <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Fund Raising</h4>
                                </div>
                            </div>
                            <Card.Body>
                                <ul className="media-story list-inline m-0 p-0">
                                    <li className="d-flex mb-4 align-items-center">
                                        <img src={user01} alt="story3" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Anna Sthesia</h5>
                                            <p className="mb-0">Today</p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <img src={user2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Paul Molive</h5>
                                            <p className="mb-0">Tomorrow</p>
                                        </div>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                        <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Suggested Services</h4>
                                </div>
                                <div className="card-header-toolbar d-flex align-items-center">
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <i className="ri-more-fill h4"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="dropdownMenuButton01">
                                            <Dropdown.Item  href="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                            <Dropdown.Item  href="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <Card.Body>
                                <ul className="suggested-page-story m-0 p-0 list-inline">
                                    <li className="mb-3">
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={img42} alt="story-img" className="rounded-circle img-fluid avatar-50"/>
                                            <div className="stories-data ms-3">
                                            <h5>Iqonic Studio</h5>
                                            <p className="mb-0">Lorem Ipsum</p>
                                            </div>
                                        </div>
                                        <img src={img9} className="img-fluid rounded" alt="Responsive"/>
                                        <div className="mt-3"><Link to="#" className="btn d-block"><i className="ri-thumb-up-line me-2"></i> Like Page</Link></div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center mb-3">
                                            <img src={img42} alt="story-img" className="rounded-circle img-fluid avatar-50"/>
                                            <div className="stories-data ms-3">
                                            <h5>Cakes & Bakes </h5>
                                            <p className="mb-0">Lorem Ipsum</p>
                                            </div>
                                        </div>
                                        <img src={img10} className="img-fluid rounded" alt="Responsive"/>
                                        <div className="mt-3"><Link to="#" className="btn d-block"><i className="ri-thumb-up-line me-2"></i> Like Page</Link></div>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{height: "100px"}}/>
                    </div>
                </Row>
            </Container>                
        </>
    )
}

export default InSearchOf
