import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    event: [],
    status: 'idle',
    error: null,
};

const EventsSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
      setEventsStatus: (state, action) => {
        state.status = action.payload;
      },
      setEvents: (state, action) => {
        state.event = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      addEvent: (state, action) => {
        state.event = [...state.event, ...[action.payload]];
        state.status = 'completed';
        state.error = null;
      },
      setEventsError: (state, action) => {
        state.error = action.payload;
        state.status = 'completed';
      },
    },
  });
  export const {
    setEvents,
    addEvent,
    setEventsError,
    setEventsStatus,
  } = EventsSlice.actions;

  export default EventsSlice;