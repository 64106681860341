import React,{useState} from 'react'
import {Container, Card, Row, Col, Dropdown, OverlayTrigger, Tooltip, Button, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { AiOutlineStar } from 'react-icons/ai';
import { RiShareForwardFill } from 'react-icons/ri';


//image

import user4 from '../../../assets/images/user/08.jpg'
import user10 from '../../../assets/images/user/04.jpg'
import user9 from '../../../assets/images/user/03.jpg'
import user12 from '../../../assets/images/user/03.jpg'
import user13 from '../../../assets/images/user/01.jpg'

import header from '../../../assets/images/page-img/profile-bg7.jpg' 
import { useSelector } from 'react-redux'

const FundRaisingDetail = () => {
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)




    return (
        <>
        
        <ProfileHeader  img={header} title="Fund Raising Title" />
                <div id="content-page" className="content-page">
            <Container>
                <Row className='pt-5 mt-5'>
                   
                    <Col lg="12" className=''>
                         <Card id="post-modal-data" className="card">
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="user-img">
                                        <img src={user9} alt="userimg" className="avatar-60 rounded-circle"/>
                                    </div>
                                    <div className='mx-3 mt-2'>
                                        <h3>Fun Raising Name</h3>
                                      <p>Fund Raiser Started for __ by __</p>
                                    </div>
                                    
                                    
                                </div>
                                <hr/>
                                <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap justify-content-end">
                                    <li>
                                    <button  onClick={handleShow2} className='btn btn-primary w-100 d-flex justify-content-center align-items-center'>
                                                     Donate
                                                </button>
                                    </li>
                                    <li>
                                        <button className='btn btn-primary mx-2 h4'>Going</button>
                                    </li>
                                    <li>
                                    <button className='btn btn-primary mx-2 h4'>
                                        Share
                                        <RiShareForwardFill className='h4 mx-1 text-white' />
                                    </button>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                        
                           {/* Fund Raising Modal */}
                      <Modal show={show2} onHide={handleClose2} size="lg" className='mt-5 pt-5'>
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Donate Fund To Poor</h5>
                                            <button type="button" className="btn btn-secondary"  onClick={handleClose2} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                           headrerfor d
                                        </Modal.Body>
                                    </Modal>                   

                    </Col>
                    <Col lg="7" className='pt-5 mt-1'>
                        <Card className=" mb-4">
                                                
                                                <Card.Body className=" text-left">
                                                
                                                    <div className="group-info pb-2">
                                                        <Link to="fund-detail">
                                                        <h4>Goal</h4>
                                                        </Link>
                                                        
                                                    </div>
                                                    <div className="text-left">
                                                        <p className='small text-muted'>2500 of 3000 generated</p>
                                                        <div style={{marginTop:"-12px"}} className="progress mb-4">
                                                            <div className={`progress-bar w-75 ${"75" ==="100" ? "bg-success":""}`} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">75%</div>
                                                        </div>
                                                    </div>
                                                    <div className="group-details text-muted d-inline-block">
                                                        <p>Pariatur elit consequat sit Lorem cupidatat ea anim. Proident commodo et qui laborum. 
                                                            Do culpa ad proident ex deserunt reprehenderit. Minim ex commodo laboris ullamco ipsum in. 
                                                            Nulla dolore commodo cillum non anim fugiat minim velit.</p>
                                                    </div>

                                                    <div>
                                                    <button className='btn w-100 btn-primary h4'>
                                                        Share
                                                        <RiShareForwardFill className='h4 mx-1 text-white' />
                                                    </button>
                                                    </div>

                                                </Card.Body>
                        </Card>
                        <Card className=" mb-4">
                                                
                                                <Card.Body className=" text-left">
                                                
                                                    <div className="group-info pb-2">
                                                        <Link to="fund-detail">
                                                        <h4>About Fund Raising</h4>
                                                        </Link>
                                                        
                                                    </div>
                                                    
                                                    <div className="group-details text-muted d-inline-block">
                                                        <p>Pariatur elit consequat sit Lorem cupidatat ea anim. Proident commodo et qui laborum. 
                                                            Do culpa ad proident ex deserunt reprehenderit. Minim ex commodo laboris ullamco ipsum in. 
                                                            Nulla dolore commodo cillum non anim fugiat minim velit.</p>
                                                    </div>

                                                    <div>
                                                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                                    </div>

                                                </Card.Body>
                        </Card>
                        <p>Activity</p>
                        <Card className=" mb-4">
                                                
                          <Card.Body className=" text-left">           
                            <div className="group-info pb-2 d-flex align-items-center">
                                <h4>Elli Banini Donated</h4>
                                <p class="card-text mx-5"><small class="text-muted">Last updated 3 mins ago</small></p>
                            </div>  
                            <hr />
                            <button className='btn btn-primary w-100'>
                                    Share
                                    <RiShareForwardFill className='h4 mx-1 text-white' />
                            </button>
                        </Card.Body>
                        </Card>
                        <Card className=" mb-4">
                                                
                          <Card.Body className=" text-left">           
                            <div className="group-info pb-2 d-flex align-items-center">
                                <h4>Raymond Tetteh Donated</h4>
                                <p class="card-text mx-5"><small class="text-muted">Last updated 3 hours ago</small></p>
                            </div>  
                            <hr />
                            <button className='btn btn-primary w-100'>
                                    Share
                                    <RiShareForwardFill className='h4 mx-1 text-white' />
                            </button>
                        </Card.Body>
                        </Card>
                        <Card className=" mb-4">
                                                
                          <Card.Body className=" text-left">           
                            <div className="group-info pb-2 d-flex align-items-center">
                                <h4>Cyril Boy Donated</h4>
                                <p class="card-text mx-5"><small class="text-muted">Last updated 2 days ago</small></p>
                            </div>  
                            <hr />
                            <button className='btn btn-primary w-100'>
                                    Share
                                    <RiShareForwardFill className='h4 mx-1 text-white' />
                            </button>
                        </Card.Body>
                        </Card>
                    </Col>

                    <Col lg="5" className='pt-5 mt-1'>
                    <Card id="post-modal-data" className="card">
                            <Card.Header className="">
                                <div className="header-title text-center">
                                    <h4 className="card-title font-bold text-center">Fund Raising Progress</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-between mx-2 mt-3">
                                   <div className='d-flex flex-column'>
                                        <h4 className='font-weight-bold text-center'>200</h4>
                                        <p>donated</p>
                                   </div>
                                   <div className='d-flex flex-column'>
                                        <h4 className='font-weight-bold text-center'>200</h4>
                                        <p>donated</p>
                                   </div>
                                   <div className='d-flex flex-column'>
                                        <h4 className='font-bold text-center'>200</h4>
                                        <p>donated</p>
                                   </div>
                                </div>

                              
                        
                            </Card.Body>
                    
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default FundRaisingDetail