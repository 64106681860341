import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eventMembers: [],
    eachEvent:null,
    status: 'idle',
    error: null,
};

const EventMemberssSlice = createSlice({
    name: 'eventMembers',
    initialState,
    reducers: {
      setEventMemberssStatus: (state, action) => {
        state.status = action.payload;
      },
      setEventMemberss: (state, action) => {
        state.eventMembers = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      setEachEventMemberss: (state, action) => {
        state.eachEvent = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      addEventMembers: (state, action) => {
        state.eventMembers = [...state.eventMembers, ...[action.payload]];
        state.status = 'completed';
        state.error = null;
      },
      setEventMemberssError: (state, action) => {
        state.error = action.payload;
        state.status = 'completed';
      },
    },
  });
  export const {
    setEventMemberss,
    addEventMembers,
    setEventMemberssError,
    setEventMemberssStatus,
  } = EventMemberssSlice.actions;

  export default EventMemberssSlice;