/* eslint-disable react-hooks/rules-of-hooks */
import { database } from "../redux/firebase";
import {getAuth, updateProfile, createUserWithEmailAndPassword} from "firebase/auth"
import { useDispatch } from "react-redux";
import { collection, addDoc } from "firebase/firestore";
import { setStatus, setUser } from "../redux/reducers/slices/auth.slice";


// !!!IMPORTANT: UPDATE STATE OF USER AFTER SIGN UP
const auth = getAuth()
const collectionRef = collection(database, 'users');


export const signUp2 =  ({email, password, fullName}) => async(dispatch) => {

  try {
    dispatch(setStatus('loading'))
    const {user} = await createUserWithEmailAndPassword(auth, email, password)
    console.log("profile created")

    await updateProfile(auth.currentUser,{   displayName: fullName    });
    console.log("profile updated")

    const userData = {
      uid:user.uid,
      email:user.email,
      displayName: fullName,
      createdAt:JSON.stringify(new Date()),
      accessToken:user.accessToken,
      phoneNumber: user.phoneNumber,
      photoURL:user.photoURL,
  }

  await addDoc(collectionRef, userData)
  console.log("Collection saved to database")
  dispatch(setUser(userData))
  dispatch(setStatus('success'))


  } catch (error) {
    console.log("Error")
    console.log(error.message);
    dispatch(setStatus('success'))
  }
}

export const signUp =({email, password, fullName})=>{
    console.log("Thi is the sign up funct")
 

    createUserWithEmailAndPassword(auth, email, password)
    .then((res)=>{
        const user =res.user
        console.log(user)
        const userData = {
            uid:user.uid,
            email:user.email,
            displayName: fullName,
            createdAt:new Date(),
            accessToken:user.accessToken,
            phoneNumber: user.phoneNumber,
            photoURL:user.photoURL,
        }
        // Update User name
        updateProfile(auth.currentUser, {
            displayName: fullName
          }).then(() => {
            console.log("profile updated")
            // ...
          }).catch((error) => {
            console.log(error.message)
            // ...
          });
        // Add to user collection
        addDoc(collectionRef, userData)
        .then(()=>{
            console.log("Collection saved to database")
        })
        .catch((error)=>{
            console.log(error.message)
        })
    })
    .catch((error)=>{
        console.log("Error")
        console.log(error.message);
    })
    // try{
    //     const auth = getAuth;
    //     dispatch(setStatus('loading'));
    //     const {user} = await createUserWithEmailAndPassword(auth, email, password);
    //     console.log("Displaying Data from firebase")
    //     console.log(user)
    // }catch (error) {
    //     dispatch(setError(error.message));
    //     dispatch(setStatus('failed'))
    // }  
}
// create a firebase collection called categories and add the following data: prosperity, health, love, career, family, friends, spirituality, fun, and personal growth., life purpose, and abundance
