import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Container, Row,Col, Nav,Tab, Button,  OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import Card from '../../../../components/Card'
import FsLightbox from 'fslightbox-react';

import imgn56 from '../../../../assets/images/page-img/56.jpg'
import imgp11 from '../../../../assets/images/user/11.png'
import imgp08 from '../../../../assets/images/icon/08.png'
import imgp09 from '../../../../assets/images/icon/09.png'
import imgp10 from '../../../../assets/images/icon/10.png'
import imgp13 from '../../../../assets/images/icon/13.png'
import imgn51 from '../../../../assets/images/page-img/51.jpg'
import imgn59 from '../../../../assets/images/page-img/59.jpg'
import imgn55 from '../../../../assets/images/page-img/55.jpg'
import imgn54 from '../../../../assets/images/page-img/54.jpg'
import imgn53 from '../../../../assets/images/page-img/53.jpg'
import imgn57 from '../../../../assets/images/page-img/57.jpg'
import imgn58 from '../../../../assets/images/page-img/58.jpg'
import imgn60 from '../../../../assets/images/page-img/60.jpg'
import imgn61 from '../../../../assets/images/page-img/61.jpg'
import imgn62 from '../../../../assets/images/page-img/62.jpg'
import imgn64 from '../../../../assets/images/page-img/64.jpg'
import imgn02 from '../../../../assets/images/user/02.jpg'
import imgn03 from '../../../../assets/images/user/03.jpg'
import cv from '../../../../assets/files/CORE_CV_template_2.pdf'
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../../store/redux/firebase';
import {  getJobs, getUserArray } from '../../../../store/create-events/get';
const JobForms=()=>{
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const jobs = useSelector((state) => state.job.job).filter((job) => job.owner.id == auth.currentUser.uid)
    const jobApplication = useSelector((state) => state.job.job).filter((jobapp) => jobapp.jobApplicants.includes(auth.currentUser.uid))
    const myJobApplicants = useSelector((state) => state.jobApplicant.jobApplicant)
    const [imageController, setImageController] = useState({
        toggler: false,
        slide: 1
    });
    
    function imageOnSlide(number) {
        setImageController({
        toggler: !imageController.toggler,
        slide: number
        }); 
    }

    const fileUrl = cv;

    const getJobApplicantMembers = (jobApplicantsId) =>{
        console.log(jobApplicantsId)
        dispatch(getUserArray(jobApplicantsId, "jobApplicants"))
        console.log(myJobApplicants)
    }


    useEffect(() => {
        dispatch(getJobs())
        console.log(jobApplication)
    //   jobApplication.map((jobapp) => jobapp.jobApplicants.filter((applicant) => applicant == auth.currentUser.uid))
    
     
    }, [])
    

    return(
        <>
            <FsLightbox
                toggler={imageController.toggler}
                sources={[imgn51,imgn59,imgn55,imgn54,imgn53,imgn56,imgn57,imgn58,imgn59,imgn60,imgn61,imgn62,imgn59,imgn55,imgn59,imgn60,imgn61,imgn51,imgn59,imgn64,imgn59,imgn60,imgn61]}
                slide={imageController.slide}
            />

            <Container>
                <Row>
                   
                    <Col lg="12">
                        <Tab.Container defaultActiveKey="f1">  
                            <Card className="cardnavbar">  
                                <div className="user-tabing">
                                    <Nav  variant="pills"  className="d-flex align-items-left justify-content-left profile-feed-items p-0 m-0 round">
                                        <Nav.Item as="li" className="col-12 col-sm-2 p-0">
                  <Nav.Link  eventKey="f1" href="#"> Job Forms</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="col-12 col-sm-3 p-0">
                  <Nav.Link className="" eventKey="f2" href="#">My Applications</Nav.Link>
                  </Nav.Item>
               
                                    </Nav>
                                </div>
                            </Card>
                            <Tab.Content className="forum-content">               
                            <Tab.Pane eventKey="f1">
                            <Row>
                                
                                 
                                            <Col sm="12" >

                                                {
                                                    jobs.map((job) => (

                                                        <Card id="post-modal-data" className="card">
                                                            <Card.Header className="d-flex justify-content-between">
                                                                <div className="header-title">
                                                                    <h4 className="card-title">{job.jobTitle}</h4>
                                                                </div>
                                                                <Button onClick={() =>{handleShow();getJobApplicantMembers(job.jobApplicants);}} className='btn btn-primary'> View Job Applicants</Button>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <div className="d-flex flex-column align-items-left">
                                                                    <div className='d-flex align-center'>
                                                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                        <i className="icon material-symbols-outlined">
                                                                        add_business
                                                                        </i>
                                                                        </OverlayTrigger>
                                                                        <p>Company Name: <span className='font-weight-bold'>{job.companyName}</span></p>

                                                                    </div>
                                                                    <div className='d-flex align-center'>
                                                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                        <i className="icon material-symbols-outlined">
                                                                        person
                                                                        </i>
                                                                        </OverlayTrigger>
                                                                        <p>Job Summary: {job.jobSummary}</p>

                                                                    </div>
                                                                    <div className='d-flex align-center'>
                                                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                        <i className="icon material-symbols-outlined">
                                                                        location_on
                                                                        </i>
                                                                        </OverlayTrigger>
                                                                        <p>Job Location Type: {job.jobLocationType}</p>

                                                                    </div>
                                                            
                                                                    <div className='d-flex align-center'>
                                                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                        <i className="icon material-symbols-outlined">
                                                                        schedule
                                                                        </i>
                                                                        </OverlayTrigger>
                                                                        <p>Time Posted:{ JSON.parse(job.createdAt)}</p>

                                                                    </div>
                                                                    <div className='d-flex align-center'>
                                                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                        <i className="icon material-symbols-outlined">
                                                                        people
                                                                        </i>
                                                                        </OverlayTrigger>
                                                                        <p>Applied People: {job.jobApplicants.length}</p>

                                                                    </div>
                                                                
                                                            
                                                                </div>
                                                        
                                                            </Card.Body>
                                                        </Card>
                                                    ))
                                                }
                                            
                                                
                                               
                                            </Col>
                                
                                        
                                
                            </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="f2">
                            <Row>
                               <Col sm='12'>
                                    {
                                        jobApplication.map((jobApplication) => (
                                                <Card id="post-modal-data" className="card">
                                                                <Card.Header className="d-flex justify-content-between">
                                                                    <div className="header-title">
                                                                        <h4 className="card-title">Job Application</h4>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column align-items-left">
                                                                        <div className='d-flex align-center'>
                                                                            <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                            <i className="icon material-symbols-outlined">
                                                                            add_business
                                                                            </i>
                                                                            </OverlayTrigger>
                                                                            <p>Company Name: <span className='font-weight-bold'>{jobApplication.companyName}</span></p>

                                                                        </div>
                                                                        <div className='d-flex align-center'>
                                                                            <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                            <i className="icon material-symbols-outlined">
                                                                            person
                                                                            </i>
                                                                            </OverlayTrigger>
                                                                            <p>Job Title: {jobApplication.jobTitle}</p>

                                                                        </div>
                                                                        <div className='d-flex align-center'>
                                                                            <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                            <i className="icon material-symbols-outlined">
                                                                            location_on
                                                                            </i>
                                                                            </OverlayTrigger>
                                                                            <p>Job Location Type: {jobApplication.jobLocationType}</p>

                                                                        </div>
                                                                
                                                                        <div className='d-flex align-center'>
                                                                            <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                            <i className="icon material-symbols-outlined">
                                                                            schedule
                                                                            </i>
                                                                            </OverlayTrigger>
                                                                            <p>Date Applied: 23hrs</p>

                                                                        </div>
                                                                        <div className='d-flex align-center'>
                                                                            <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                                                            <i className="icon material-symbols-outlined">
                                                                            hourglass_empty
                                                                            </i>
                                                                            </OverlayTrigger>
                                                                            <p>Status: Pending</p>

                                                                        </div>
                                                                    
                                                                    
                                                                
                                                                    </div>
                                                            
                                                                </Card.Body>
                                            </Card>  
                                        ))
                                    }
                                    
                               </Col>
                        
                            </Row>
                            </Tab.Pane>
                            
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
                <Modal show={show} onHide={handleClose} size="lg" className='mt-5 pt-5'>
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Job Applicants</h5>
                                            <button type="button" className="btn btn-danger"  onClick={handleClose} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                           
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                   <p>Name: <strong>John Doe</strong></p> 
                                                   <p>Date applied: <strong>3rd January, 2022</strong></p> 
                                                    
                                                </div>
                                                <div className=''>
                                                <a href={fileUrl} target="_blank" rel="noopener noreferrer" className='btn btn-info'>View CV</a>

                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
            </Container>

        </>
    )
}
export default JobForms