import React,{useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown} from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'

//img
import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import { getAuth } from 'firebase/auth'
import { app, database } from '../../../store/redux/firebase'
import { useDispatch, useSelector } from 'react-redux'
import { collection, getDocs, query, where } from 'firebase/firestore'
// import page100 from '../../../assets/images/page-img/100.jpg'

const Chat=()=>{
    const dispatch = useDispatch()
    const [show, setShow] = useState('')
    const [show1, setShow1] = useState('')
    const [show2, setShow2] = useState('')
    const [msgUsers, setMsgUsers] = useState([])
    const ChatSidebar =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }

    let history =useHistory()


    // User data
    const currentUser = getAuth(app).currentUser
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    const gettingUsers = async()=> {
        const q = query(collection(database, "users"), where("uid", "!=", currentUser.uid))
        const querySnapshot = await getDocs(q);
        console.log("we run sthe sity")
            querySnapshot.forEach((doc) => {
                console.log(doc.data)
              });
    }
    console.log(currentUser)

    useEffect(() => {
      gettingUsers()
    

    }, [])
    
    return(
        <>
            <Tab.Container id="left-tabs-example"  defaultActiveKey="start"> 
                {isLoggedIn ? (
     <Row>
     <Col sm="12">
         <Card>
             <Card.Body className="chat-page p-0">
                 <div className="chat-data-block">
                     <Row>
                         <Col lg="3" className="chat-data-left scroller">
                             <div className="chat-search pt-3 ps-3">
                                 <div className="d-flex align-items-center">
                                     <div className="chat-profile me-3">
                                         <img loading="lazy" src={user1} alt="chat-user" className="avatar-60 " onClick={() => setShow1('true')}/>
                                     </div>
                                     <div className="chat-caption">
                                         <h5 className="mb-0">Bni Jordan</h5>
                                         <p className="m-0">Web Designer</p>
                                     </div>
                                 </div>
                                 <div id="user-detail-popup" className={`scroller ${show1 === 'true' ? 'show' : '' }`}>
                                     <div className="user-profile">
                                         <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow1('false')}>close</i></Button>
                                         <div className="user text-center mb-4">
                                             <Link className="avatar m-0" to="">
                                                 <img loading="lazy" src={user1} alt="avatar"/>
                                             </Link>
                                             <div className="user-name mt-4">
                                                 <h4 className="text-center">Bni Jordan</h4>
                                             </div>
                                             <div className="user-desc">
                                                 <p className="text-center">Web Designer</p>
                                             </div>
                                         </div>
                                         <hr/>
                                         <div className="user-detail text-left mt-4 ps-4 pe-4">
                                             <h5 className="mt-4 mb-4">About</h5>
                                             <p>It is long established fact that a reader will be distracted bt the reddable.</p>
                                             <h5 className="mt-3 mb-3">Status</h5>
                                             <ul className="user-status p-0">
                                                 <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-success pe-1"></i><span>Online</span></li>
                                                 <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-warning pe-1"></i><span>Away</span></li>
                                                 <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-danger pe-1"></i><span>Do Not Disturb</span></li>
                                                 <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-light pe-1"></i><span>Offline</span></li>
                                             </ul>
                                         </div>
                                     </div>
                                 </div>
                                 <div className="chat-searchbar mt-4">
                                     <Form.Group className="form-group chat-search-data m-0">
                                         <input type="text" className="form-control round" id="chat-search" placeholder="Search"/>
                                             <i className="material-symbols-outlined">
                                                 search
                                             </i>
                                     </Form.Group>
                                 </div>
                             </div>
                             <div className="chat-sidebar-channel scroller mt-4 ps-3">
                             <h5 >Public Channels</h5>
                             <Nav as="ul" variant="pills" className="iq-chat-ui nav flex-column">
                                 <Nav.Item as="li">
                                     <Nav.Link eventKey="first" onClick={() => setShow('first')}    href="#chatbox1">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user5} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Team Discussions</h6>
                                                 <span>Lorem Ipsum is</span>
                                             </div>
                                             <div className="chat-meta float-right text-center mt-2 me-1">
                                                 <div className="chat-msg-counter bg-primary text-white">20</div>
                                                 <span className="text-nowrap">05 min</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </Nav.Item>
                                 <li>
                                     <Nav.Link  eventKey="second"  onClick={() => setShow('second')}    href="#chatbox2">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user6} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Announcement</h6>
                                                 <span>This Sunday we</span>
                                             </div>
                                             <div className="chat-meta float-right text-center mt-2 me-1">
                                                 <div className="chat-msg-counter bg-primary text-white">10</div>
                                                 <span className="text-nowrap">10 min</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                             </Nav>
                             <h5 className="mt-3">Private Channels</h5>
                             <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                 <li>
                                     <Nav.Link  eventKey="third" onClick={() => setShow('third')} href="#chatbox3">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user7} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Designer</h6>
                                                 <span>There are many </span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                                 <li>
                                     <Nav.Link  eventKey="forth" onClick={() => setShow('forth')} href="#chatbox4">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user8} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Developer</h6>
                                                 <span>passages of Lorem</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                                 <li>
                                     <Nav.Link  eventKey="five" onClick={() => setShow('five')} href="#chatbox5">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user9} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-info"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Testing Team</h6>
                                                 <span>Lorem Ipsum used</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                             </Nav>
                             <h5 className="mt-3">Direct Message</h5>
                             <Nav variant="pills" className="iq-chat-ui nav flex-column ">
                                 <li>
                                     <Nav.Link  eventKey="six" onClick={() => setShow('six')} href="#chatbox6">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user10} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Paul Molive</h6>
                                                 <span>translation by</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                                 <li>
                                     <Nav.Link  eventKey="seven" onClick={() => setShow('seven')} href="#chatbox7">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user5} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Paige Turner</h6>
                                                 <span>Lorem Ipsum which</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                                 <li>
                                     <Nav.Link  eventKey="eight" onClick={() => setShow('eight')} href="#chatbox8">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user6} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-primary"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Barb Ackue</h6>
                                                 <span>simply random text</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                                 <li>
                                     <Nav.Link  eventKey="nine" onClick={() => setShow('nine')} href="#chatbox9">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user7} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-danger"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Maya Didas</h6>
                                                 <span> but also the leap</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                                 <li>
                                     <Nav.Link  eventKey="ten" onClick={() => setShow('ten')} href="#chatbox10">
                                         <div className="d-flex align-items-center">
                                             <div className="avatar me-2">
                                                 <img loading="lazy" src={user8} alt="chatuserimage" className="avatar-50 "/>
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                                             </div>
                                             <div className="chat-sidebar-name">
                                                 <h6 className="mb-0">Monty Carlo</h6>
                                                 <span>Contrary to popular</span>
                                             </div>
                                         </div>
                                     </Nav.Link>
                                 </li>
                             </Nav>
                             </div>
                         </Col>
                         <Col lg={9} className=" chat-data p-0 chat-data-right">
                             <Tab.Content>
                                 <Tab.Pane eventKey="start" className="tab-pane fade show" id="default-block" role="tabpanel">
                                     <div className="chat-start">
                                         <span className="iq-start-icon text-primary"><i className="material-symbols-outlined md-42">sms</i></span>
                                         <Button id="chat-start" onClick={ChatSidebar} bsPrefix="btn bg-white mt-3">Start
                                         Conversation!</Button>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane  eventKey="first"  className={`fade ${show === 'first' ? 'show' : ''}`} id="chatbox1"  role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-50 "  onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
                                                 </div>
                                                 <h5 className="mb-0">Team Discussions</h5>
                                             </div>
                                             <div  className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user mb-4  text-center">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user5} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Bni Jordan</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6  title">Bni Name:</Col>
                                                             <Col md="6" className="col-6  text-right">Bni</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Male</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="second" className={`fade ${show === 'second' ? 'show' : ''}`} id="chatbox2"  role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Announcement</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`} >
                                                 <div className="user-profile">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user mb-4 text-center">
                                                             <Link className="avatar m-0" to="">
                                                                 <img loading="lazy" src={user6} alt="avatar"/>
                                                             </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Mark Jordan</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Atlanta, USA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6  text-right">Mark</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6  text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6  text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6  text-right">Female</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6  text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" className=" primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="third"  className={`fade ${show === 'third' ? 'show' : ''}`} id="chatbox3" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Designer</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`} >
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user text-center mb-4">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user7} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Paige Turner</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">pai</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Male</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex ">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="forth" className={`fade ${show === 'forth' ? 'show' : ''}`} id="chatbox4" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Developer</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user mb-4 text-center">
                                                             <Link className="avatar m-0" to="">
                                                                 <img loading="lazy" src={user8} alt="avatar"/>
                                                             </Link>
                                                             <div className="user-name mt-4">
                                                                 <h4>Barb Ackue</h4>
                                                             </div>
                                                             <div className="user-desc">
                                                                 <p>Cape Town, RSA</p>
                                                             </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">Babe</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Feale</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="five" className={`fade ${show === 'five' ? 'show' : ''}`} id="chatbox5" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                             <div className="avatar chat-user-profile m-0 me-3">
                                                 <img loading="lazy" src={user9} alt="avatar" className="avatar-50 "  />
                                                 <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                             </div>
                                             <h5 className="mb-0">Testing Team</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user mb-4 text-center">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user9} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Peta Saireya</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">Pet</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Female</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user9} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user9} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user9} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" className=" primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="six" className={`fade ${show === 'six' ? 'show' : ''}`} id="chatbox6" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user10} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Paul Molive</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user mb-4 text-center">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user10} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Paul Molive</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Bni Name:</Col>
                                                             <Col md="6"  className="col-6 text-right">Pau</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Tel:</Col>
                                                             <Col md="6"  className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6"  className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Gender:</Col>
                                                             <Col md="6"  className="col-6 text-right">Male</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6"  className="col-6 title">Language:</Col>
                                                             <Col md="6"  className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone  bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video  bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete  bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user10} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user10} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user10} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="seven" className={`fade ${show === 'seven' ? 'show' : ''}`} id="chatbox7" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Paige Turner</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user text-center mb-4">
                                                             <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user5} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Paige Turner</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">Pai</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Feale</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                 <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user5} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" className=" primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="eight" className={`fade ${show === 'eight' ? 'show' : ''}`} id="chatbox8" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Barb Ackue</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user text-center mb-4">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user6} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Barb Ackue</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">Babe</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Female</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                 <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user6} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="nine" className={`fade ${show === 'nine' ? 'show' : ''}`} id="chatbox9" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')}/>
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Maya Didas</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="user text-center mb-4">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user7} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Maya Didas</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">Babe</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Male</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user7} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="ten" id="chatbox10" role="tabpanel">
                                     <div className="chat-head">
                                         <header className="d-flex justify-content-between align-items-center bg-white pt-3 ps-3 pe-3 pb-3">
                                             <div className="d-flex align-items-center">
                                                 <div className="sidebar-toggle">
                                                     <i className="ri-menu-3-line"></i>
                                                 </div>
                                                 <div className="avatar chat-user-profile m-0 me-3">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-50 " onClick={() => setShow2('true')} />
                                                     <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success"></i></span>
                                                 </div>
                                                 <h5 className="mb-0">Monty Carlo</h5>
                                             </div>
                                             <div className={`chat-user-detail-popup scroller ${show2 === 'true' ? 'show' : '' }`}>
                                                 <div className="user-profile ">
                                                 <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow2('false')}>close</i></Button>
                                                     <div className="text-center user mb-4">
                                                         <Link className="avatar m-0" to="">
                                                             <img loading="lazy" src={user8} alt="avatar"/>
                                                         </Link>
                                                         <div className="user-name mt-4">
                                                             <h4>Monty Carlo</h4>
                                                         </div>
                                                         <div className="user-desc">
                                                             <p>Cape Town, RSA</p>
                                                         </div>
                                                     </div>
                                                     <hr/>
                                                     <div className="chatuser-detail text-left mt-4">
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Bni Name:</Col>
                                                             <Col md="6" className="col-6 text-right">Babe</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Tel:</Col>
                                                             <Col md="6" className="col-6 text-right">072 143 9920</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Date Of Birth:</Col>
                                                             <Col md="6" className="col-6 text-right">July 12, 1989</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Gender:</Col>
                                                             <Col md="6" className="col-6 text-right">Female</Col>
                                                         </Row>
                                                         <hr/>
                                                         <Row>
                                                             <Col md="6" className="col-6 title">Language:</Col>
                                                             <Col md="6" className="col-6 text-right">Engliah</Col>
                                                         </Row>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="chat-header-icons d-flex">
                                                 <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">phone</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-video bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">videocam</i>
                                                 </Link>
                                                 <Link to="#" className="chat-icon-delete bg-soft-primary d-flex justify-content-center align-items-center">
                                                     <i className="material-symbols-outlined md-18">delete</i>
                                                 </Link>
                                                 <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
                                                     <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                         more_vert
                                                     </Dropdown.Toggle>
                                                     <Dropdown.Menu className="dropdown-menu-right">
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                                                         <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">watch_later</i>Block</Dropdown.Item>
                                                     </Dropdown.Menu>
                                                 </Dropdown>
                                             </div>
                                         </header>
                                     </div>
                                     <div className="chat-content scroller">
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:45</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>How can we help? We're here for you! 😄</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                                 <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:48</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Hey John, I am looking for the best admin template.</p>
                                                     <p>Could you please help me to find it out? 🤔</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:49</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Absolutely!</p>
                                                     <p>SocialV Dashboard is the responsive bootstrap 5 admin template.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:52</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Looks clean and fresh UI.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:53</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Thanks, from ThemeForest.</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat chat-left">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user8} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:54</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>I will purchase it for sure. 👍</p>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="chat d-flex other-user">
                                             <div className="chat-user">
                                                 <Link className="avatar m-0" to="">
                                                     <img loading="lazy" src={user1} alt="avatar" className="avatar-35 "/>
                                                 </Link>
                                                 <span className="chat-time mt-1">6:56</span>
                                             </div>
                                             <div className="chat-detail">
                                                 <div className="chat-message">
                                                     <p>Okay Thanks..</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="chat-footer p-3 bg-white">
                                         <Form className="d-flex align-items-center" action="#">
                                             <div className="chat-attagement d-flex">
                                                 <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true"></i></Link>
                                                 <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true"></i></Link>
                                             </div>
                                             <Form.Control type="text" className="form-control me-3" placeholder="Type your message"/>
                                             <Button type="submit" variant="primary d-flex align-items-center"><i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span></Button>
                                         </Form>
                                     </div>
                                 </Tab.Pane>
                             </Tab.Content>
                         </Col>
                     </Row>
                 </div>
             </Card.Body>
         </Card>
     </Col>
 </Row>
                ):(
                    <div className="text-center pt-5 mt-5">
                        <button onClick={()=> history.push('/auth/sign-in')} className='btn btn-primary'>Login to see Chats</button>
                    </div>
                    
                )}                       
           
                </Tab.Container>
        </>
    )
}
export default Chat;    