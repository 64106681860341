import React,{useState} from 'react'
import {Container, Card, Row, Col, Image, Form, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'

// img
import image1 from '../../../../assets/images/page-img/img-success.png'
import { useDispatch } from 'react-redux'
import { submitJobApplications } from '../../../../store/create-events/post'

const JobApplicationForm = () => {
    const [show, AccountShow] = useState('A');
    const [cvFile, setCVFile] = useState(null);
    const dispatch = useDispatch()

    const [jobApplicationForm, setJobApplicationForm] = useState({
        jobAppliedTo:'91d461f8-b55d-40e8-aabf-a2060a9e36ed',
        haveExperience:null,
        jobExperience:null,
        previousCompanyRole:'',
        leavingReason:'',
        firstName:'',
        lastName:'',
        contactNo:'',
        altContactNo:''
        
    })

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
          setCVFile(e.target.files[0]);
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobApplicationForm((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };


    const submittingJobApplication= ()=>{
        console.log(jobApplicationForm)
        dispatch(submitJobApplications(jobApplicationForm,cvFile));
        AccountShow('Image')
    }
    return (
        <>
            <Container>
                <Row>
                    <Col sm="12">
                        <Card className="position-relative inner-page-bg bg-primary" style={{height: "150px"}}>
                            <div className="inner-page-title">
                                <h3 className="text-white">Software Engineer Application</h3>
                                <p className="text-white">lorem ipsum</p>
                            </div>
                        </Card>
                    </Col>
                    <Col sm="12" lg="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Job Application</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form id="form-wizard1" className="text-center mt-3">
                                    <ul id="top-tab-list" className="p-0 row list-inline">
                                        <li className={ ` ${show === 'Image' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''} ${show === 'Account' ? 'active done' : ''} ${show === 'A' ? 'active ' : ''} col-lg-3 col-md-6 text-start mb-2 active`} id="account">
                                            <Link to="#">
                                                <i className="material-symbols-outlined">lock_open</i><span>Experience</span>
                                            </Link>
                                        </li>
                                        <li id="personal" className={` ${show === 'Image' ? 'active done' : ''} ${show === 'Personal' ? 'active done' : ''} ${show === 'Account' ? 'active done' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <i className="material-symbols-outlined">person</i><span>Personal</span>
                                            </Link>
                                        </li>
                                        <li id="payment" className={` ${show === 'Image' ? 'active done': ''} ${show === 'Personal' ? 'active done' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <i className="material-symbols-outlined">photo_camera</i><span>CV Upload</span>
                                            </Link>
                                        </li>
                                        <li id="confirm" className={` ${show === 'Image' ? 'active done' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <i className="material-symbols-outlined">done</i><span>Finish</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <fieldset className={`${show === 'A' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4">Account Information:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 1 - 4</h2>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                <Form.Group className="form-group">
                                                        <Form.Label htmlFor="exampleFormControlSelect1">Do you have Work Experience</Form.Label>
                                                        <Form.Control type="hidden" name="jobExperience" onChange={handleChange} value='48a32351-338d-4acc-8112-10095f1be54c' />
                                                        <select className="form-select"name="haveExperience" onChange={handleChange} value={jobApplicationForm.haveExperience}  >
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>  
                                
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>If "Yes" to Work Experience, how many</Form.Label>
                                                        <Form.Control type="number" name="jobExperience" onChange={handleChange} value={jobApplicationForm.jobExperience} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Role in your previous Company </Form.Label>
                                                        <Form.Control type="text" name="previousCompanyRole" onChange={handleChange} value={jobApplicationForm.previousCompanyRole} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Reason for Leaving</Form.Label>
                                                        <Form.Control type="text" name="leavingReason" onChange={handleChange} value={jobApplicationForm.leavingReason} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Button variant="primary" name="next" className="next action-button float-end" value="Next" onClick={() => AccountShow('Account')} >Next</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Account' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4">Personal Information:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 2 - 4</h2>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>First Name: *</Form.Label>
                                                        <Form.Control type="text" name="firstName" onChange={handleChange} value={jobApplicationForm.firstName} placeholder="First Name" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Last Name: *</Form.Label>
                                                        <Form.Control type="text" name="lastName" onChange={handleChange} value={jobApplicationForm.lastName} placeholder="Last Name" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Contact No.: *</Form.Label>
                                                        <Form.Control type="text" name="contactNo" onChange={handleChange} value={jobApplicationForm.contactNo} placeholder="Contact No." />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Alternate Contact No.: *</Form.Label>
                                                        <Form.Control type="text" name="altContactNo" onChange={handleChange} value={jobApplicationForm.altContactNo} placeholder="Alternate Contact No." />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Button variant="primary" name="next" className="next action-button float-end" value="Next" onClick={() => AccountShow('Personal')} >Next</Button>
                                        <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('A')} >Previous</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Personal' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4">Image Upload:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 3 - 4</h2>
                                                </div>
                                            </Row>
                                            <Form.Group className="form-group">
                                                <Form.Label>Upload Your CV:</Form.Label>
                                                <Form.Control type="file" onChange={handleFileChange} name="cv" accept="pdf/*"/>
                                            </Form.Group>
                                            {/* <Form.Group className="form-group">
                                                <Form.Label>Upload Signature Photo:</Form.Label>
                                                <Form.Control type="file" name="pic-2" accept="image/*"/>
                                            </Form.Group> */}
                                        </div>
                                        <Button variant="primary" name="next" className="next action-button float-end" value="Submit" onClick={submittingJobApplication}>Submit</Button>
                                        <Button variant="dark" name="previous" className="previous action-button-previous float-end me-3" value="Previous" onClick={() => AccountShow('Account')} >Previous</Button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Image' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card">
                                            <Row>
                                                <div className="col-7">
                                                    <h3 className="mb-4 text-left">Finish:</h3>
                                                </div>
                                                <div className="col-5">
                                                    <h2 className="steps">Step 4 - 4</h2>
                                                </div>
                                            </Row>
                                            <br/><br/>
                                            <h2 className="text-success text-center"><strong>SUCCESS !</strong></h2>
                                            <br/>
                                            <Row className="justify-content-center">
                                                <div className="col-3"> 
                                                    <Image src={image1} className="img-fluid" alt="fit-image"/>
                                                </div>
                                            </Row>
                                            <br/><br/>
                                            <Row className="justify-content-center">
                                                <div className="col-7 text-center">
                                                    <h5 className="purple-text text-center">You Have Successfully Submitted Your Application</h5>
                                                </div>
                                            </Row>
                                        </div>
                                    </fieldset>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default JobApplicationForm
