import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    job: [],
    status: 'idle',
    error: null,
};

const JobsSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
      setJobsStatus: (state, action) => {
        state.status = action.payload;
      },
      setJobs: (state, action) => {
        state.job = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      addJob: (state, action) => {
        state.job = [...state.job, ...[action.payload]];
        state.status = 'completed';
        state.error = null;
      },
      setJobsError: (state, action) => {
        state.error = action.payload;
        state.status = 'completed';
      },
    },
  });
  export const {
    setJobs,
    addJob,
    setJobsError,
    setJobsStatus,
  } = JobsSlice.actions;

  export default JobsSlice;