import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fund: [],
    status: 'idle',
    error: null,
};

const FundsSlice = createSlice({
    name: 'fund',
    initialState,
    reducers: {
      setFundsStatus: (state, action) => {
        state.status = action.payload;
      },
      setFunds: (state, action) => {
        state.fund = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      addFund: (state, action) => {
        state.fund = [...state.fund, ...[action.payload]];
        state.status = 'completed';
        state.error = null;
      },
      setFundsError: (state, action) => {
        state.error = action.payload;
        state.status = 'completed';
      },
    },
  });
  export const {
    setFunds,
    addFund,
    setFundsError,
    setFundsStatus,
  } = FundsSlice.actions;

  export default FundsSlice;