import React,{useEffect, useState} from 'react'
import {Container, Card, Row, Col, Dropdown, OverlayTrigger, Tooltip, Button, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { RiShareForwardFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';


//image

import map from '../../../assets/images/user/map.jpg'
import success from '../../../assets/images/page-img/img-success.png'
import failure from '../../../assets/images/page-img/img-failure.png'


import header from '../../../assets/images/page-img/profile-bg7.jpg' 
import { useDispatch, useSelector } from 'react-redux'
import { isInterestedInEventorNot } from '../../../store/create-events/post'
import { auth } from '../../../store/redux/firebase'

const EventDetail = () => {
    let [isInterested, setIsInterested] = useState(false)
    
    const [isInterestedField, setIsInterestedField] = useState({
        title:"",
        text:"",
        media:success
    })


    const dispatch = useDispatch()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    const location = useLocation();
    const eachEvent = location.state.value;
    const eventDetailData = 
        {
            id:1,
            totalPeople:245,
            eventHost: "Digiits Agency",
            safetyPrecautions:"covid 19 vaccine",
            duration:"2 hours",
            access:"Public",
            tags:["Awesome", "Technology", "Artificial Intelligence"],
            eventDate: "20th September, 2023",
            eventTitle: "Bringing Technology to Africa",
            eventDescription:"Nostrud ad aute adipisicing tempor aliqua. Dolore elit aliqua eiusmod dolore nulla. Veniam ea officia sunt commodo nisi aliquip sunt ea sint id dolor. Minim in duis reprehenderit exercitation officia velit incididunt qui cillum non commodo. Sint aliquip culpa mollit dolor Lorem enim reprehenderit officia nisi. Ad occaecat sint minim deserunt. Deserunt occaecat incididunt deserunt reprehenderit aliquip et Lorem enim aute.",
            location: "Airpot Residential",
            detailLocation: "Airpot Residential with GPS other landmarks",
            eventGuests:["Elli Banini", "Raymond Tetteh", "Cyril Amoah", "Godfed Nhyira"],
        }
        

    const handleToggle = () => {
        
    if (isInterested==false) {
        isInterested=true
        setIsInterested(true)
    } else {
        isInterested=false
        setIsInterested(false)
    }
    console.log(isInterested)

        dispatch(isInterestedInEventorNot(eachEvent.post_id, isInterested))
        if(isInterested){
            isInterestedField.text="Thank You For Subscibing to this event"
            isInterestedField.title="You Have Subscribed"
            isInterestedField.media=success
        }else{
            isInterestedField.text="Ok we will unsubscribe you from this Event"
            isInterestedField.title="You Have Unsubscribed"
            isInterestedField.media=failure
        }
        setTimeout(()=>{
            handleShow()
         }, 2000)
    }

    
        useEffect(() => {
          console.log(eachEvent)
        
        }, [])
        

    return (
        <>
        
        <ProfileHeader  img={eachEvent.eventImage} title={eachEvent.eventTitle} />
                <div id="content-page" className="content-page">
            <Container>
                <Row className='pt-5 mt-5'>
                    <Col lg="12">
                     
                    </Col>
                   
                    <Col lg="12" className=''>
                         <Card id="post-modal-data" className="card">
                           
                            <Card.Body>
                                <div className=" align-items-center">
                                    {/* <div className="user-img">
                                        <img src={user9} alt="userimg" className="avatar-60 rounded-circle"/>
                                    </div> */}
                                    <h3>{eachEvent.eventDate}</h3>
                                    <h2>{eachEvent.eventTitle}</h2>
                                    <p>{eachEvent.eventLocation}</p>
                                    
                                </div>
                                <hr/>
                                <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap justify-content-end">
                                    <li>
                                    <button onClick={handleToggle} className='btn btn-primary w-100 d-flex justify-content-center align-items-center'>
                                                {isInterested ? <AiFillStar className=' text-white mx-1 h6'/> : < AiOutlineStar  className=' text-white mx-1 h6'/>}                                             

                                                     interest?
                                                </button>
                                    </li>
                                    <li>
                                        <button className='btn btn-primary mx-2 h4'>Going</button>
                                    </li>
                                    <li>
                                    <button className='btn btn-primary mx-2 h4'>
                                        Share
                                        <RiShareForwardFill className='h4 mx-1 text-white' />
                                    </button>
                                    </li>
                                </ul>
                            </Card.Body>
                    
                        </Card>
                        
            
                        

                    </Col>
                    <Col lg="7" className='pt-5 mt-1'>
                        <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Details</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex flex-column align-items-left">
                                    <div className='d-flex align-center'>
                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                        <i className="icon material-symbols-outlined">
                                        people
                                        </i>
                                        </OverlayTrigger>
                                        <p>Total People Attending: <span className='font-weight-bold'>{eachEvent.interestedPeople.length}</span></p>

                                    </div>
                                    <div className='d-flex align-center'>
                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                        <i className="icon material-symbols-outlined">
                                        person
                                        </i>
                                        </OverlayTrigger>
                                        <p>Hosted By: {eachEvent.eventHost}</p>

                                    </div>
                                    <div className='d-flex align-center'>
                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                        <i className="icon material-symbols-outlined">
                                        location_on
                                        </i>
                                        </OverlayTrigger>
                                        <p>Location: {eachEvent.eventLocation}</p>

                                    </div>
                                    <div className='d-flex align-center'>
                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                        <i className="icon material-symbols-outlined">
                                        safety_check
                                        </i>
                                        </OverlayTrigger>
                                        <p>Safety Protocol: {eachEvent.safetyPrecautions}</p>

                                    </div>
                                    <div className='d-flex align-center'>
                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                        <i className="icon material-symbols-outlined">
                                        schedule
                                        </i>
                                        </OverlayTrigger>
                                        <p>Duration: {eachEvent.eventDuration} hours</p>

                                    </div>
                                    <div className='d-flex align-center'>
                                        <OverlayTrigger placement="right" overlay={<Tooltip>FundRaising</Tooltip>}>
                                        <i className="icon material-symbols-outlined">
                                        public
                                        </i>
                                        </OverlayTrigger>
                                        <p>Access to:{eachEvent.accessType}</p>

                                    </div>
                                    <div>
                                        Tags:{eventDetailData.tags.map((tag) => (
                                            <button className='btn btn-outline-dark btn-sm mx-2'>{tag}</button>
                                        ))}
                                    </div>
                                </div>
                        
                            </Card.Body>
                    
                        </Card>
                        <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">About Event</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <p>{eachEvent.eventDescription}</p>
                        
                            </Card.Body>
                    
                        </Card>
                    </Col>
                    <Col lg="5" className='pt-5 mt-1'>
                    <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Map Location</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex flex-column align-items-center">
                                   <img src={map} className="img-fluid" />
                                </div>
                        
                            </Card.Body>
                    
                        </Card>
                    <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Guest Speakers</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex flex-column align-items-left font-weight-bold">
                                    {/* {eventDetailData.eventGuests.map((guest)=> (
                                        <p className='h5 font-weight-bolder my-2'>{guest}</p>
                                    ))} */}
                                    {eachEvent.eventGuests}
                                </div>
                        
                            </Card.Body>
                    
                        </Card>
                    </Col>

                    <Modal show={show} className='mt-5 pt-5' onHide={handleClose} size="lg">
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">{isInterestedField.title}</h5>
                                            <button type="button" className="btn btn-danger"  onClick={handleClose} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <h3>{isInterestedField.text}</h3>
                                       
                                        <div className="d-flex align-center justify-content-center"> 
                                                    <img src={isInterestedField.media} className="img-fluid" width={150} alt="fit-image"/>
                                                </div>
                                        </Modal.Body>
                                    </Modal>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default EventDetail