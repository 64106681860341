import { getAuth } from "firebase/auth";
import { app, database, auth, storage } from "../redux/firebase"
import { doc, setDoc, set, getDoc } from "firebase/firestore"; 
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useSelector } from "react-redux";
import { v4 as uuid } from 'uuid';
import { getHashLocation } from "./geolocation";



const {lat, lng, hash} = getHashLocation();

export const submitPost2 = (body, image)=>async(dispatch)=>{
    const storageRef = ref(storage, `post-images/${image.name}`)
    console.log(auth.currentUser)
    try {
        await uploadBytesResumable(storageRef, image)
        const getDownloadURL2 = await getDownloadURL(storageRef)
        console.log(getDownloadURL2)
        console.log("submitting post")
        const unique_id = uuid();
        console.log(unique_id)
        const post = await setDoc(doc(database,"posts",unique_id ), {
            postId: unique_id,
            body: body,
            images:[getDownloadURL2],
            createdAt:JSON.stringify(new Date()),
            likes:0,
            location:"",
            tags:[],
            comments:[],
            owner:{
                id:auth.currentUser.uid,
                name:auth.currentUser.displayName,
                jobTitle:"",
                profileImage:auth.currentUser.photoURL,
            },
             location: {
                lat,
                lng,
                hash
                }
      });
    console.log("post submited")
    console.log(post)
    } catch (error) {
        console.log(error)
    }
}
export const submitEvent = ({accessType, eventDate, eventDuration, eventGuests, eventHost, eventLocation, eventTitle, safetyPrecautions, eventDescription}, image)=>async(dispatch)=>{
    const storageRef = ref(storage, `event-images/${image.name}`)
    console.log(auth.currentUser)
    try {
        await uploadBytesResumable(storageRef, image)
        const getDownloadURL2 = await getDownloadURL(storageRef)
        console.log(getDownloadURL2)
        console.log("submitting post")
        const unique_id = uuid();
        console.log(unique_id)
        const event = await setDoc(doc(database,"events",unique_id ), {
            post_id: unique_id,
            accessType,
            eventDate,
            eventDuration,
            eventImage:getDownloadURL2,
            eventGuests,
            eventHost,
            eventLocation,
            eventTitle,
            safetyPrecautions,
            eventDescription,
            interestedPeople:[],
            createdAt:JSON.stringify(new Date()),
            owner:{
                id:auth.currentUser.uid,
                name:auth.currentUser.displayName,
                jobTitle:"",
                profileImage:auth.currentUser.photoURL,
            },
             location: {
                                    lat,
                                    lng,
                                    hash
                                }
      });
    console.log("event submited")
    console.log(event)
    } catch (error) {
        console.log(error)
    }
}

// ! Important to Check select type or default it
// Forums
export const submitForum =
                    ({forumName,
                    totalMembers,
                    forumAccessType,
                    postType}, image)=>async(dispatch)=>{
                        const storageRef = ref(storage, `forum-images/${image.name}`)
                        console.log(auth.currentUser)
                        try {
                            await uploadBytesResumable(storageRef, image)
                            const getDownloadURL2 = await getDownloadURL(storageRef)
                            console.log(getDownloadURL2)
                            console.log("submitting forum")
                            const unique_id = uuid();
                            console.log(unique_id)
                            const forum = await setDoc(doc(database,"forums",unique_id ), {
                                forumId: unique_id,
                                forumName,
                                totalMembers,
                                forumAccessType,
                                postType,
                                forumImage:getDownloadURL2,
                                createdAt:JSON.stringify(new Date()),
                                owner:{
                                    id:auth.currentUser.uid,
                                    name:auth.currentUser.displayName,
                                    jobTitle:"",
                                    profileImage:auth.currentUser.photoURL,
                                },
                                 location: {
                                    lat,
                                    lng,
                                    hash
                                }
                        });
                        console.log("forum submited")
                        console.log(forum)
                        } catch (error) {
                            console.log(error)
                        }
                    }

// Funds
export const submitFunds =
                    ({
                        fundRaisingName,
                        fundRaiser,
                        totalTargetAmount,
                        fundRaisingDescription
                    }, image)=>async(dispatch)=>{
                        const storageRef = ref(storage, `fund-images/${image.name}`)
                        console.log(auth.currentUser)
                        try {
                            await uploadBytesResumable(storageRef)
                            const getDownloadURL2 = await getDownloadURL(storageRef)
                            console.log(getDownloadURL2)
                            console.log("submitting fund")
                            const unique_id = uuid();
                            console.log(unique_id)
                            const fund = await setDoc(doc(database,"funds",unique_id ), {
                                fundId: unique_id,
                                fundRaisingName,
                                fundRaiser,
                                totalTargetAmount,
                                totalAchievedAmount:0,
                                fundRaisingDescription,
                                fundRaisingImage:getDownloadURL2,
                                createdAt:JSON.stringify(new Date()),
                                owner:{
                                    id:auth.currentUser.uid,
                                    name:auth.currentUser.displayName,
                                    jobTitle:"",
                                    profileImage:auth.currentUser.photoURL,
                                },
                                 location: {
                                    lat,
                                    lng,
                                    hash
                                }
                        });
                        console.log("fund submited")
                        console.log(fund)
                        } catch (error) {
                            console.log(error)
                        }
                    }

// Jobs
export const submitJobs =
                    ({
                        jobTitle,
                        companyLocation,
                        companyName,
                        jobLocationType,
                        jobSummary,
                        jobQualifications,
                        jobResponsibilities,
                    })=>async(dispatch)=>{
                        console.log(auth.currentUser)
                        try {
                          
                            console.log("submitting job")
                            const unique_id = uuid();
                            console.log(unique_id)
                            const job = await setDoc(doc(database,"jobs"                            
                            ,unique_id ), {
                                jobId: unique_id,
                                jobTitle,
                                companyLocation,
                                companyName,
                                jobLocationType,
                                jobSummary,
                                jobQualifications,
                                jobResponsibilities,
                                createdAt:JSON.stringify(new Date()),
                                owner:{
                                    id:auth.currentUser.uid,
                                    name:auth.currentUser.displayName,
                                    jobTitle:"Software Developer",
                                    profileImage:auth.currentUser.photoURL,
                                },
                                location: {
                                    lat,
                                    lng,
                                    hash
                                },
                                jobApplicants:[]
                        });
                        console.log("job submited")
                        console.log(job)
                        console.log("locations")
                        console.log(lat)
                        console.log(lng)
                        console.log(hash)
                        } catch (error) {
                            console.log(error)
                        }
                    }


export const submitJobApplications =
                    ({
                        jobAppliedTo,
                        haveExperience,
                        jobExperience,
                        previousCompanyRole,
                        leavingReason,
                        firstName,
                        lastName,
                        contactNo,
                        altContactNo,
                    }, cvFile)=>async(dispatch)=>{
                        console.log(auth.currentUser)
                        const storageRef = ref(storage, `job-application/${auth.currentUser.uid}/${cvFile.name}`)

                        try {
                            await uploadBytesResumable(storageRef, cvFile)
                            const getDownloadURL2 = await getDownloadURL(storageRef)
                            console.log(getDownloadURL2)
                            console.log("submitting job")
                            const unique_id = uuid();
                            console.log(unique_id)
                            const jobApplication = await setDoc(doc(database,"jobApplications",unique_id ), {
                                jobApplicationId: unique_id,
                                jobAppliedTo,
                                haveExperience,
                                jobExperience,
                                previousCompanyRole,
                                leavingReason,
                                firstName,
                                lastName,
                                contactNo,
                                altContactNo,
                                cvFileURL:getDownloadURL2,
                                createdAt:JSON.stringify(new Date()),
                                owner:{
                                    id:auth.currentUser.uid,
                                    name:auth.currentUser.displayName,
                                    jobTitle:"Software Developer",
                                    profileImage:auth.currentUser.photoURL,
                                },
                                location: {
                                    lat,
                                    lng,
                                    hash
                                },
                        });
                        console.log("job submited")
                        console.log(jobApplication)
                        console.log("searching for job")
                        const getJob = await getDoc(doc(database, 'jobs', jobAppliedTo));
                        const udpateJobData = getJob.data();
                        udpateJobData.jobApplicants.push(auth.currentUser.uid);
                        console.log(udpateJobData)
                        await setDoc(doc(database, 'jobs', jobAppliedTo), udpateJobData)
                        console.log("job updated")

                        } catch (error) {
                            console.log(error)
                        }
                    }

export const isInterestedInEventorNot = (eventId, value) => async(dispatch) =>{

    try {
        if (value) {
            console.log("the participant is interesed")   
            const getEvent = await getDoc(doc(database, 'events', eventId));
                        const eventApplied = getEvent.data();
                        eventApplied.interestedPeople.push(auth.currentUser.uid);
                        console.log(eventApplied)
                        await setDoc(doc(database, 'events', eventId), eventApplied)
                        console.log("event updated updated")
        } else {
            console.log("The participant wishes to unsubscibe")
            const getEvent = await getDoc(doc(database, 'events', eventId));
                        const eventApplied = getEvent.data();
                        const newArr =eventApplied.interestedPeople.filter((event) => event !== auth.currentUser.uid);
                        console.log(newArr)
                        eventApplied.interestedPeople.length=0;
                        eventApplied.interestedPeople.concat(newArr)
                        console.log(eventApplied)
                        await setDoc(doc(database, 'events', eventId), eventApplied)
                        console.log("event updated deleted")
        }
    
        

    } catch (error) {
        
    }
}