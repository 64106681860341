import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    jobApplicant: [],
    status: 'idle',
    error: null,
};

const JobApplicantsSlice = createSlice({
    name: 'jobApplicant',
    initialState,
    reducers: {
      setJobApplicantsStatus: (state, action) => {
        state.status = action.payload;
      },
      setJobApplicants: (state, action) => {
        state.jobApplicant = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      addJobApplicant: (state, action) => {
        state.jobApplicant = [...state.jobApplicant, ...[action.payload]];
        state.status = 'completed';
        state.error = null;
      },
      setJobApplicantsError: (state, action) => {
        state.error = action.payload;
        state.status = 'completed';
      },
    },
  });
  export const {
    setJobApplicants,
    addJobApplicant,
    setJobApplicantsError,
    setJobApplicantsStatus,
  } = JobApplicantsSlice.actions;

  export default JobApplicantsSlice;