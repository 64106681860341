import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { database } from "../redux/firebase";
import { addJob, setJobs } from "../redux/reducers/slices/job.slice";
import { setHashLocation } from "./geolocation";
import { setJobApplicants } from "../redux/reducers/slices/job-applicant.slice";
import { setPosts } from "../redux/reducers/slices/post.slice";
import { setEvents } from "../redux/reducers/slices/event.slice";
import { setFunds } from "../redux/reducers/slices/fund.slice";
import { setEventMemberss } from "../redux/reducers/slices/event-members.slice";

const bounds = setHashLocation()

export const getJobs =()=> async(dispatch) =>{
    const q = query(collection(database, 'jobs'), where('location.hash', '>=', bounds[0][0]+bounds[0][1]), where('location.hash', '<=', bounds[1][0]+bounds[1][1]) );
    console.log(bounds)
    const querySnapshot = await getDocs(q);
    const jobs = querySnapshot.docs.map((item)=> item.data())
    console.log(querySnapshot)
    dispatch(setJobs(jobs))

  
}
export const getEvents =()=> async(dispatch) =>{
    const q = query(collection(database, 'events'), where('location.hash', '>=', bounds[0][0]+bounds[0][1]), where('location.hash', '<=', bounds[1][0]+bounds[1][1]) );
    console.log(bounds)
    const querySnapshot = await getDocs(q);
    const events = querySnapshot.docs.map((item)=> item.data());
    dispatch(setEvents(events))

}

export const getForums =()=> async(dispatch) =>{
    const results = [];
    const q = query(collection(database, 'forums'), where('location.hash', '>=', bounds[0][0]+bounds[0][1]), where('location.hash', '<=', bounds[1][0]+bounds[1][1]) );
    console.log(bounds)
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        console.log(bounds[0][0]+bounds[0][1])
        addJob(doc.data)
      });

  
}
export const getPosts =()=> async(dispatch) =>{
    const results = [];
    const q = query(collection(database, 'posts'), where('location.hash', '>=', bounds[0][0]+bounds[0][1]), where('location.hash', '<=', bounds[1][0]+bounds[1][1]) );
    console.log(bounds)
    const querySnapshot = await getDocs(q);
    const posts = querySnapshot.docs.map((item)=> item.data())
    dispatch(setPosts(posts))


  
}
export const getFunds =()=> async(dispatch) =>{
    const results = [];
    const q = query(collection(database, 'funds'), where('location.hash', '>=', bounds[0][0]+bounds[0][1]), where('location.hash', '<=', bounds[1][0]+bounds[1][1]) );
    console.log(bounds)
    const querySnapshot = await getDocs(q);
    const funds = querySnapshot.docs.map((item)=> item.data());
    dispatch(setFunds(funds))

  
}

export const getUserArray= (userArrayId, dispatchType) => async(dispatch) =>{
    console.log("mdddddddd")
    const docRef = collection(database, 'users');
    const dq = query(docRef, where("uid", "in", userArrayId));
    try {
        dispatch(setJobApplicants(null))
        const querySnapshots = await getDocs(dq);
        const UIDArray = querySnapshots.docs.map((item)=> item.data())
        if (dispatchType == "jobApplicants") {
            console.log("setting Job applicants")
            dispatch(setJobApplicants(UIDArray))   
        }else if( dispatchType = "interestedPeople"){
            console.log("setting interested People")
            dispatch(setEventMemberss(UIDArray))
        }

    } catch (error) {
        console.log(error)
    }
    
}

