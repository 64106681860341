import { combineReducers } from "redux";
import settingSlice from "../../setting/reducers";
import authSlice from "./slices/auth.slice";
import notificationSlice from "./slices/notification.slice";
import JobsSlice from "./slices/job.slice";
import JobApplicantsSlice from "./slices/job-applicant.slice";
import PostSlice from "./slices/post.slice";
import EventsSlice from "./slices/event.slice";
import FundsSlice from "./slices/fund.slice";
import EventMemberssSlice from "./slices/event-members.slice";

const rootReducer = combineReducers({
    auth:authSlice.reducer,
    setting: settingSlice.reducer,
    notification: notificationSlice.reducer,  
    job:JobsSlice.reducer, 
    jobApplicant:JobApplicantsSlice.reducer, 
    post:PostSlice.reducer,
    event:EventsSlice.reducer,
    fund:FundsSlice.reducer,
    eventMembers:EventMemberssSlice.reducer,
    
})

export default rootReducer;