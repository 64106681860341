import { async } from "@firebase/util";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const resetPassword = (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Email sent for password reset")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)
        // ..
      });
}

export const resetPassword2 =(email) => async(dispatch) =>{
  const auth = getAuth()
  try {
    const passwordReset = await sendPasswordResetEmail(auth, email)
    console.log(passwordReset)
    console.log("password Reset")
  } catch (error) {
    console.log("password not Reset")
    console.log(error.message)

  }
} 