/* eslint-disable react-hooks/rules-of-hooks */
import { app, database } from "../redux/firebase";
import {getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider} from "firebase/auth"
import { useDispatch } from "react-redux";
import { doc, getDoc, collection, setDoc} from "firebase/firestore";
import { setStatus, setUser } from "../redux/reducers/slices/auth.slice";
import { notifyIsLoggedIn } from "../redux/reducers/slices/notification.slice";


// !!!IMPORTANT: UPDATE STATE OF USER AFTER SIGN IN
const auth = getAuth()
const provider = new GoogleAuthProvider();
const collectionRef = collection(database, 'users');



export const signIn2 = ({email, password}) => async(dispatch) => {
    
    try {
        const {user} = await signInWithEmailAndPassword(auth, email, password);
        console.log("User logged in")
        const userData = {
            uid:user.uid,
            email:user.email,
            displayName: user.displayName,
            createdAt:JSON.stringify(new Date()),
            accessToken:user.accessToken,
            phoneNumber: user.phoneNumber,
            photoURL:user.photoURL,
        }
        console.log({userData})
        dispatch(setUser(userData))
        dispatch(setStatus('success'))
        console.log("success")
        dispatch(notifyIsLoggedIn('Login Successful'));
    } catch (error) {
        console.log("Error")
        dispatch(notifyIsLoggedIn('Invalid User Credentials'));
        console.log(error.message);
        dispatch(setStatus('fail'))
    }
}

export const signInWithGoogle = () => async(dispatch) => {

    try{
        const result = await signInWithPopup(auth,provider)
        const user = result.user;
        const userData = {
            uid:user.uid,
            email:user.email,
            displayName: user.displayName,
            createdAt:JSON.stringify(new Date()),
            accessToken:user.accessToken,
            phoneNumber: user.phoneNumber,
            photoURL:user.photoURL,
        }
        dispatch(setUser(userData))
        dispatch(setStatus('success'))
        console.log("success")
        dispatch(notifyIsLoggedIn('Login Successful'));

        const docRef = doc(database, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            await setDoc(doc(database, "users", user.uid), userData);

          }

        // const q = query(collection(database, "users"), where("uid", "==", user.uid));
        // const querySnapshot = await getDocs(q);
        // console.log(querySnapshot)
        // if(querySnapshot.size ===0){
        //     const setToUser = await addDoc(collectionRef, userData);
        //     console.log("Added to user table")
        //     console.log(setToUser)
        // }else{
        //     querySnapshot.forEach((doc) => {
        //         console.log("Getting user from")
        //       });
        // }

        // const docRef = doc(database,"users", user.uid )
        // const docSnap = await getDoc(docRef)
        // if(docSnap.exists){
        //     console.log("Already exist in user table")
        //     console.log(docSnap)
        // }else{

        //     console.log(docSnap.data)


        //     const setToUser = await addDoc(collectionRef, userData);
        //     console.log("Added to user table")
        //     console.log(setToUser)
        // }
    }catch(error){
        console.log(error.message)
    }
}

// export const signInWithGoogle = () => async (dispatch) =>{
//     const provider = new GoogleAuthProvider();

//     signInWithPopup(auth, provider)
//   .then((result) => {
//     // This gives you a Google Access Token. You can use it to access the Google API.
//     const credential = GoogleAuthProvider.credentialFromResult(result);
//     const token = credential.accessToken;
//     const user = result.user;
//     console.log(user)

//     const docRef = doc(database, "users", user.uid)

//     const docSnap = await getDoc(docRef)
    
//     const userData = {
//         uid:user.uid,
//         email:user.email,
//         displayName: user.displayName,
//         createdAt:new Date(),
//         accessToken:user.accessToken,
//         phoneNumber: user.phoneNumber,
//         photoURL:user.photoURL,
//     }
//     // ...
//   }).catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // The email of the user's account used.
//     const email = error.customData.email;
//     // The AuthCredential type that was used.
//     const credential = GoogleAuthProvider.credentialFromError(error);
//     // ...
//   });
// }


