import React,{useState} from 'react'
import { Container, Modal, Dropdown, Form, Col, Row, Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import { BsFillCheckCircleFill } from 'react-icons/bs';


// images
import gi1 from '../../../assets/images/page-img/gi-1.jpg'
import gi2 from '../../../assets/images/page-img/gi-2.jpg'
import gi3 from '../../../assets/images/page-img/gi-3.jpg'
import gi4 from '../../../assets/images/page-img/gi-4.jpg'
import gi5 from '../../../assets/images/page-img/gi-5.jpg'
import gi6 from '../../../assets/images/page-img/gi-6.jpg'
import gi9 from '../../../assets/images/page-img/gi-9.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img2 from '../../../assets/images/page-img/profile-bg2.jpg'
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img4 from '../../../assets/images/page-img/profile-bg4.jpg'
import img5 from '../../../assets/images/page-img/profile-bg5.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import img7 from '../../../assets/images/page-img/profile-bg7.jpg'
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'
import user9 from '../../../assets/images/user/1.jpg'
import small1 from '../../../assets/images/small/07.png'

import { useDispatch, useSelector } from 'react-redux'
import { submitForum } from '../../../store/create-events/post'

const Groups =() =>{
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    const [image, setImage] = useState(null);
    const [forumForm, setForumForm] = useState({
        forumName:'',
        totalMembers:null,
        forumAccessType:null,
        postType:null

    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForumForm((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      const handleFileChange = (e) => {
        if (e.target.files[0]) {
          setImage(e.target.files[0]);
        }
      };

    const forumSubmitting = async() =>{
        console.log(forumForm)
        console.log(image)
        dispatch(submitForum(forumForm, image))
    }
    const forumsData = [
        {
            id:1,
            title: "Diigits Forums",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img1,
            img:gi1
        },
        {
            id:2,
            title: "Immigrants Forums",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img2,
            img:gi2
        },
        {
            id:3,
            title: "Jobs Search     ",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img3,
            img:gi3
        },
        {
            id:4,
            title: "Ghana Immigrants",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img4,
            img:gi4
        },
        {
            id:5,
            title: "Money Making",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img5,
            img:gi5
        },
        {
            id:6,
            title: "Nigerian Immigrants",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img6,
            img:gi6
        },
        {
            id:7,
            title: "Togo Immigrants",
            description: "lorem ipsum alberta novela",
            post: 600,
            members: 320,
            visits: 1200,
            background: img9,
            img:gi9
        },
    ]
    return(
        <>
            <ProfileHeader  img={img7} title="Forums" />
                <div id="content-page" className="content-page">
                    <Container>
                        <div className='my-4'>
                        {isLoggedIn &&  
                         <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create a Forum</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="user-img">
                                        <img src={user9} alt="userimg" className="avatar-60 rounded-circle"/>
                                    </div>
                                    <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                        <input type="text" className="form-control rounded" placeholder="Write something here..." style={{border: "none"}}/>
                                    </form>
                                </div>
                                {/* <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small1} alt="icon" className="img-fluid me-2"/> Photo/Video</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small2} alt="icon" className="img-fluid me-2"/> Tag Friend</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3"><img src={small3} alt="icon" className="img-fluid me-2"/> Feeling/Activity</li>
                                     <li className="bg-soft-primary rounded p-2 pointer text-center">
                                        <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <i className="ri-more-fill h4"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="post-option">
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Check in</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Live Video</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Gif</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Watch Party</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Play with Friend</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </li> 
                                </ul> */}
                            </Card.Body>
                            
                            <Modal show={show} className='mt-5 pt-5' onHide={handleClose} size="lg">
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Create Forum</h5>
                                            <button type="button" className="btn btn-danger"  onClick={handleClose} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            
                                             <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Group Title: *</Form.Label>
                                                        <Form.Control type="text" name="forumName" value={forumForm.forumName} onChange={handleChange} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Group Total Members</Form.Label>
                                                        <Form.Control type="number" name="totalMembers" value={forumForm.totalMembers} onChange={handleChange} placeholder='maximum is 50'  />                                                   </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="exampleFormControlSelect1">Forum Access Type</Form.Label>
                                                        <select className="form-select"name="forumAccessType" value={forumForm.forumAccessType} onChange={handleChange}>
                                                            <option value="none">Whether access to all or not</option>
                                                            <option value="public">Public</option>
                                                            <option value="private">Private</option>
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="exampleFormControlSelect1">Group Posting</Form.Label>
                                                        <select className="form-select"name="postType" value={forumForm.postType} onChange={handleChange} >
                                                            <option value="none">Select Whether members can Post</option>
                                                            <option value="enablePosting">Enable Posting</option>
                                                            <option value="disablePosting">Disable Posting</option>
                                                        
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                          
                                            </Row>
                                            <hr/>
                                            <div className="other-option">
                                            <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                                <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2">
                                                    <img src={small1} alt="icon" className="img-fluid me-2"/> Group_Image
                                                    <input type="file"  className="form-control rounded" onChange={handleFileChange} style={{border: "none", background:"#edf7ff", marginLeft:"2rem"}}/>
                                                    </li>
                                              </ul>
                                                
                                            </div>
                                            <Button variant="primary" onClick={forumSubmitting} className="d-block w-100 mt-3">Post</Button>
                                        </Modal.Body>
                            </Modal>
                        </Card>}
                        </div>
                        <div className="d-grid gap-3 d-grid-template-1fr-19">
                            {forumsData.map((product) => (
                                <><Card key={product.id} className="mb-0">
                                    <div className="top-bg-image">
                                        <img src={product.background} className="img-fluid w-100" alt="group-bg" />
                                    </div>
                                    <Card.Body className=" text-center">
                                        <div className="group-icon">
                                            <img src={product.img} alt="profile-img" className="rounded-circle img-fluid avatar-120" />
                                        </div>
                                        <div className="group-info pt-3 pb-3">
                                            <h4><Link to="/dashboards/app/forum-detail">{product.title}</Link></h4>
                                            <p>{product.description}</p>
                                        </div>
                                        <div className="group-details d-inline-block pb-3">
                                            <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
                                                <li className="pe-3 ps-3">
                                                    <p className="mb-0">Post</p>
                                                    <h6>{product.post}</h6>
                                                </li>
                                                <li className="pe-3 ps-3">
                                                    <p className="mb-0">Member</p>
                                                    <h6>{product.members}</h6>
                                                </li>
                                                <li className="pe-3 ps-3">
                                                    <p className="mb-0">Visit</p>
                                                    <h6>{product.visits}</h6>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="group-member mb-3">
                                            <div className="iq-media-group">
                                                <Link to="#" className="iq-media">
                                                    <img className="img-fluid avatar-40 rounded-circle" src={user05} alt="" />
                                                </Link>
                                                <Link to="#" className="iq-media">
                                                    <img className="img-fluid avatar-40 rounded-circle" src={user06} alt="" />
                                                </Link>
                                                <Link to="#" className="iq-media">
                                                    <img className="img-fluid avatar-40 rounded-circle" src={user07} alt="" />
                                                </Link>
                                                <Link to="#" className="iq-media">
                                                    <img className="img-fluid avatar-40 rounded-circle" src={user08} alt="" />
                                                </Link>
                                                <Link to="#" className="iq-media">
                                                    <img className="img-fluid avatar-40 rounded-circle" src={user09} alt="" />
                                                </Link>
                                                <Link to="#" className="iq-media">
                                                    <img className="img-fluid avatar-40 rounded-circle" src={user10} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <button type="button" onClick={handleShow2} className="btn btn-primary d-block w-100">Join</button>
                                    </Card.Body>
                                </Card>
                                <Modal show={show2} onHide={handleClose2} size="lg" className='mt-5 pt-5'>
                                        <Modal.Header className="d-flex justify-content-between">
                                            <span></span>
                                            <h5 className="modal-title" id="post-modalLabel text-center">{product.title}</h5>
                                            <button type="button" className="btn btn-danger" onClick={handleClose2}><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <p><BsFillCheckCircleFill style={{ fontSize: "5rem" }} className=' text-success' /></p>
                                                <p className='font-bold h4'>You Have Joined The Group</p>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    </>
                            ))}
                           
   
                        </div>
                    </Container>
                </div>
        </>
  )

}

export default Groups;