import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Card, Modal, Form, Button, Dropdown} from 'react-bootstrap'
import header from '../../../assets/images/page-img/profile-bg10.jpg' 
import ProfileHeader from '../../../components/profile-header';
import CustomToggle from '../../../components/dropdowns'
import {Link, useHistory } from 'react-router-dom'

import user9 from '../../../assets/images/user/03.jpg'
import profile from '../../../assets/images/user/profile.jpg'
import small1 from '../../../assets/images/small/07.png'
import small2 from '../../../assets/images/small/08.png'
import small3 from '../../../assets/images/small/09.png'
import { useDispatch, useSelector } from 'react-redux';
import { submitJobs } from '../../../store/create-events/post';
import { getJobs } from '../../../store/create-events/get';

const Jobs = () => {
    const [jobsShow, setJobsShow] =useState(false)
    const history = useHistory();
    const myJobs = useSelector((state) => state.job.job)
    const dispatch = useDispatch()
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [info, setDisplayInfo]= useState(0);
    const [jobsForm, setJobsForm] = useState({
        jobTitle:'',
        companyLocation:null,
        companyName:null,
        jobLocationType:null,
        jobSummary:'',
        jobQualifications:'',
        jobResponsibilities:null

    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobsForm((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

    const jobsSubitting = () =>{

        dispatch(submitJobs(jobsForm));
        handleClose();

    }
    const jobsData =  [
         {
            id:0,
            jobTitle: "Plumber",
            jobCompany: "Digiits Agency",
            datePosted: "20 hours ago",
            jobLocation: "Remote",
            companyLocation: "Airpot Residential",
            peopleApplied: 20,
            companyInfo:{
                hirerName:" Raymond Tetteh",
                hirerRole:"Human Resources",
            },
            jobSummary:"Officia enim occaecat proident laboris eu in. Ut sit mollit sint in Lorem reprehenderit.",
            jobRoles:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."],
            jobQualifications:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."]

        },
         {
            id:1,
            jobTitle: "Fissherman",
            jobCompany: "Digiits Agency",
            datePosted: "2 days ago",
            jobLocation: "Remote",
            companyLocation: "Airpot Residential",
            peopleApplied: 20,
            companyInfo:{
                hirerName:" Raymond Tetteh",
                hirerRole:"Human Resources",
            },
            jobSummary:"Officia enim occaecat proident laboris eu in. Ut sit mollit sint in Lorem reprehenderit.",
            jobRoles:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."],
            jobQualifications:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."]

        },
         {
            id:2,
            jobTitle: "Maison",
            jobCompany: "Digiits Agency",
            datePosted: "15 days ago",
            jobLocation: "Remote",
            companyLocation: "Airpot Residential",
            peopleApplied: 20,
            companyInfo:{
                hirerName:" Raymond Tetteh",
                hirerRole:"Human Resources",
            },
            jobSummary:"Officia enim occaecat proident laboris eu in. Ut sit mollit sint in Lorem reprehenderit.",
            jobRoles:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."],
            jobQualifications:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."]

        },
         {
            id:3,
            jobTitle: "Software Developer",
            jobCompany: "Digiits Agency",
            datePosted: "15 days ago",
            jobLocation: "Remote",
            companyLocation: "Airpot Residential",
            peopleApplied: 20,
            companyInfo:{
                hirerName:" Raymond Tetteh",
                hirerRole:"Human Resources",
            },
            jobSummary:"Officia enim occaecat proident laboris eu in. Ut sit mollit sint in Lorem reprehenderit.",
            jobRoles:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."],
            jobQualifications:["excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit.", "excepteur nostrud. Dolore occaecat do excepteur ex ut exercitation velit."]

        },
]

    useEffect(() => {
        dispatch(getJobs())
        console.log(myJobs)
        console.log(jobsShow)
    }, [dispatch])
    

    return (
        <>
            <ProfileHeader  img={header} title="Jobs" />
            <div id="content-page" className="content-page">
                    <Container>

                <Row>
                {isLoggedIn &&  
                         <Card id="post-modal-data" className="card">
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Create Job Add</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="user-img">
                                        <img src={profile} alt="userimg" className="avatar-60 rounded-circle"/>
                                    </div>
                                    <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                        <input type="text" className="form-control rounded" placeholder="Write something here..." style={{border: "none"}}/>
                                    </form>
                                </div>
                                <hr/>
                                <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small1} alt="icon" className="img-fluid me-2"/> Photo/Video</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3 mb-md-0 mb-2"><img src={small2} alt="icon" className="img-fluid me-2"/> Tag Friend</li>
                                    <li className="bg-soft-primary rounded p-2 pointer d-flex align-items-center me-3"><img src={small3} alt="icon" className="img-fluid me-2"/> Feeling/Activity</li>
                                    <li className="bg-soft-primary rounded p-2 pointer text-center">
                                        <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <i className="ri-more-fill h4"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="post-option">
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Check in</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Live Video</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Gif</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Watch Party</Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item"  onClick={handleShow} href="#" >Play with Friend</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                    <li className=" rounded p-2 pointer text-end px-5"><button onClick={()=> history.push('/dashboards/app/job/job-forms')} className='btn btn-outline-primary'>Job Forms</button></li>

                                </ul>
                            </Card.Body>
                            
                            <Modal show={show} onHide={handleClose} size="lg" className='mt-5 pt-5'>
                                        <Modal.Header className="d-flex justify-content-between">
                                            <h5 className="modal-title" id="post-modalLabel">Job Add</h5>
                                            <button type="button" className="btn btn-secondary"  onClick={handleClose} ><i className="ri-close-fill"></i></button>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <Row>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Job Title: *</Form.Label>
                                                        <Form.Control type="text" name="jobTitle" value={jobsForm.jobTitle} onChange={handleChange}  />
                                                    </Form.Group>
                                                </Col>
                                            
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Company Name: </Form.Label>
                                                        <Form.Control type="text" name="companyName" value={jobsForm.companyName} onChange={handleChange}   />                                                   </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Company Location: </Form.Label>
                                                        <Form.Control type="text" name="companyLocation" value={jobsForm.companyLocation} onChange={handleChange}   />                                                   </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label htmlFor="exampleFormControlSelect1">Job Location Type</Form.Label>
                                                        <select className="form-select"name="jobLocationType" value={jobsForm.jobLocationType} onChange={handleChange} >
                                                            <option value="none">Select Job Location</option>
                                                            <option value="remote">Remote</option>
                                                            <option value="onSite">On Site</option>
                                                            <option value="hybrid">Hybrid</option>
                                                        
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Fund Raising Description</Form.Label>
                                                            <Form.Control as="textarea" name='jobResponsibilities' value={jobsForm.jobResponsibilities} onChange={handleChange} rows="5"></Form.Control>
                                                        </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Fund Raising Description</Form.Label>
                                                            <Form.Control as="textarea" name='jobQualifications' value={jobsForm.jobQualifications} onChange={handleChange} rows="5"></Form.Control>
                                                        </Form.Group>
                                                </Col>
                                               
                                          
                                            </Row>
                                            <hr/>
                                     
                                            <Button variant="primary"className="d-block w-100 mt-3" onClick={jobsSubitting}>Post</Button>
                                        </Modal.Body>
                                    </Modal>

                        </Card>} 


                    <Col lg="5">
                        {myJobs && myJobs.map((product, index)=>(
                            <Card key={product.jobId}  id="post-modal-data" how className="card">
                                <Card.Body className='btn' onClick={() => {setDisplayInfo(index); setJobsShow(true)}}>
                                    <div className="d-flex ">
                                        <div className="user-img">
                                            <img src={profile} alt="userimg" className="avatar-60 rounded-circle"/>
                                        </div>
                                        <div className='mx-3 mt-2 text-start'>
                                            <h5>{product.jobTitle}</h5>
                                        <p>{product.companyName}</p>
                                        <p>{product.companyLocation} ({product.jobLocationType})</p>
                                        <div>
                                            <p>{product.createdAt}</p>
                                            <p>{product.jobApplicants.length} people have applied</p>
                                        </div>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                </Card.Body>
                            </Card>
                        ))}        
                    </Col>
                    {jobsShow &&
                          <Col lg="7">
                            <Card id="post-modal-data" className="card">
                                <Card.Body>
                                        <div className='mx-2'>
                                            <div className='d-flex justify-content-between my-3'>
                                                <h3>{myJobs[info].jobTitle}</h3>
                                                <button onClick={()=> history.push('/dashboards/app/job/job-application')} className='btn btn-primary w-25 p-0'>
                                                    Apply
                                                </button>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                    <p>{myJobs[info].companyName}</p>
                                                    <p>{myJobs[info].companyLocation} ({myJobs[info].jobLocationType})</p>
                                                    <p>{myJobs[info].jobApplicants.length} people have applied</p>
                                            
                                            </div>
                                    
                                            <div className='d-flex'>
                                                <div className="user-img mr-4">
                                                    <img src={profile} alt="userimg" className="avatar-60 rounded-circle"/>
                                                </div>
                                                <div className='d-flex flex-column px-3'>
                                                    <p className='m-0' style={{fontWeight:'600'}}>{myJobs[info].owner.name}</p>
                                                    <p className='m-0'>{myJobs[info].owner.jobTitle}</p>
                                                    <button className='btn btn-outline-dark btn-sm w-75'>Message</button>
                                                </div>
                                            </div>
                                                
                                            <div className='pt-4'>
                                                <h3>About the Job</h3>
                                                <p>{myJobs[info].jobSummary} </p> 
                                            </div>
    
                                            <div >
                                                <h4>Responsibilities</h4>
                                                <ul>
                                                        <li>{myJobs[info].jobResponsibilities}</li>
                                               
                                                </ul>
                                            </div>
                                            <div>
                                                <h4>Qualifications</h4>
                                                <ul>
                                                        <li>{myJobs[info].jobQualifications}</li>
                                      
                                                </ul>
                                            </div>
                                                
                                        </div>
                                    
                                </Card.Body>
                            </Card>
                          </Col>
                    }
                  
                   
                </Row>
            </Container>
            </div>
       
        </>
    )
}

export default Jobs