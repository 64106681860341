import React, { useState, useEffect } from 'react';
import { ref } from 'firebase/database';
import * as geofire from 'geofire-common'



const getGeoLocation = () => {
    let lat=null
    let lng = null
    navigator.geolocation.getCurrentPosition(
      (position) => {
          lat= position.coords.latitude
          lng= position.coords.longitude
      },
      () => {
        console.log('Could not get location');
      }
    );

    if (lat && lng) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCfnZd7_ywkqd9Mr-sOl_YdSz-yfdq57SQ&callback=getAddress`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      window.getAddress = () => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { location: { lat, lng } },
          (results, status) => {
            if (status === 'OK') {
              console.log(results[0].formatted_address);
            } else {
              console.log('Could not get address');
            }
          }
        );
      };
    }


    return { lat, lng };

}
  
  export default getGeoLocation;

export const getLocation =() =>{
    let lat = null;
    let lng= null;
    navigator.geolocation.getCurrentPosition(
        (position) => {
            lat= position.coords.latitude
            lng= position.coords.longitude
        },
        () => {
          console.log('Could not get location');
        }
      );

      return { lat, lng };

    }
export const getHashLocation = () => {
    const lat = 5.572760;
    const lng = -0.237140;
    const hash = geofire.geohashForLocation([lat, lng])
    return {lat, lng, hash};
}

export const setHashLocation = () => {
    const center = [5.572760, -0.237140];
    const radiusInM = 50 * 1000;
    const bounds = geofire.geohashQueryBounds(center, radiusInM);
    
    return bounds;
}

