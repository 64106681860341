import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    post: [],
    status: 'idle',
    error: null,
};

const PostSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
      setPostsStatus: (state, action) => {
        state.status = action.payload;
      },
      setPosts: (state, action) => {
        state.post = action.payload;
        state.status = 'completed';
        state.error = null;
      },
      addPost: (state, action) => {
        state.post = [...state.post, ...[action.payload]];
        state.status = 'completed';
        state.error = null;
      },
      setPostsError: (state, action) => {
        state.error = action.payload;
        state.status = 'completed';
      },
    },
  });
  export const {
    setPosts,
    addPost,
    setPostsError,
    setPostsStatus,
  } = PostSlice.actions;

  export default PostSlice;