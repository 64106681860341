import { createSlice } from '@reduxjs/toolkit';



const notificationSlice = createSlice({
    name: 'notification',
    initialState: {message: '', color: '',},
    reducers: {
        notifyIsLoggedIn: (state, action) => {
            state.message = action.payload;
            state.color = '#03fc0b';
        },
        notifyIsNotLoggedIn: (state, action) =>{
            state.message = action.payload;
            state.color = '#fc2803';
        }
    }
})

export const { notifyIsLoggedIn, notifyIsNotLoggedIn } = notificationSlice.actions;

export default notificationSlice