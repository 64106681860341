import { async } from "@firebase/util";
import { getAuth, signOut } from "firebase/auth";
import { app } from "../redux/firebase";
import { setUserOut } from "../redux/reducers/slices/auth.slice";
import { notifyIsLoggedIn } from "../redux/reducers/slices/notification.slice";

const auth = getAuth(app);

export const logOut= () => async(dispatch) => {
  try {
    const logout= await signOut(auth);
    dispatch(setUserOut())
    dispatch(notifyIsLoggedIn(''));
    console.log("user signed Out")
    console.log(logout)

  } catch (error) {
    
  }
}

// signOut(auth).then(() => {
//     console.log("Signed out")
// }).catch((error) => {
//   console.log(error.message)
// });